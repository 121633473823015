import { Roles } from "../data/common";
import {
  AddTrack,
  DictionaryAdministration,
  DictionaryShare,
  FollowTeacher,
  Landing,
  Languages,
  Library,
  Login,
  HealthCheck,
  Player,
  Podcasts,
  Policies,
  Profile,
  SituationsAdministration,
  Support,
  SituationShare,
  TeachersView,
  Users,
  Notify,
} from "../pages";
import { Chapters, Views } from "../pages/TeacherLessons/data/constants";

export const ROUTES = {
  languages: "/languages_list",
  podcasts: "/podcasts_library",
  users: "/users_list",
  add_track: "/add_track",
  profile: "/profile",
  library: "/tracks_library",
  listen: "/listen",
  dictionary: "/dictionary_administration",
  situations: "/situations_administration",
  share_situation: "/share-situation",
  share_dictionary: "/share-dictionary",
  follow_teacher: "/follow-teacher",
  healthcheck: "/healthcheck",
  privacy: "/privacy-policy",
  terms: "/terms-and-conditions",
  support: "/support",
  login: "/login",
  notify: "/notify",
  default: "/",
  teacherTutorial: "/teacher_tutorial",
  teachers: "/teachers",
  students: "/students",
  lessons: "/lessons",
  teacherTracks: "/teacher_tracks",
  teacherDictionary: "/teacher_dictionary",
  teacherSituations: "/teacher_situations",
  teacherExercises: "/teacher_exercises",
};

const ADMIN_ROUTES = [
  {
    path: ROUTES.languages,
    component: <Languages />,
    exact: true,
    title: "Languages | Pony",
  },
  {
    path: ROUTES.podcasts,
    component: <Podcasts />,
    exact: true,
    title: "Podcasts | Pony",
  },
  {
    path: ROUTES.users,
    component: <Users />,
    exact: true,
    title: "Users | Pony",
  },
  {
    path: ROUTES.add_track,
    component: <AddTrack />,
    exact: true,
    title: "Add Podcast | Pony",
  },
  {
    path: ROUTES.profile,
    component: <Profile />,
    exact: true,
    title: "Profile | Pony",
  },
  {
    path: ROUTES.library,
    component: <Library />,
    exact: true,
    title: "Library | Pony",
  },
  {
    path: ROUTES.listen,
    component: <Player />,
    exact: true,
    title: "Player | Pony",
  },
  {
    path: ROUTES.dictionary,
    component: <DictionaryAdministration />,
    title: "Dictionary | Pony",
    exact: true,
  },
  {
    path: ROUTES.situations,
    component: <SituationsAdministration />,
    title: "Situations | Pony",
    exact: true,
  },
  {
    path: ROUTES.healthcheck,
    component: <HealthCheck />,
    exact: true,
    title: "Healthcheck | Pony",
  },
  {
    path: ROUTES.notify,
    component: <Notify />,
    exact: true,
    title: "Notify | Pony",
  },
  {
    path: ROUTES.share_situation,
    component: <SituationShare />,
    replace: true,
  },
  {
    path: ROUTES.share_dictionary,
    component: <DictionaryShare />,
    replace: true,
  },
];

const PUBLIC_ROUTES = [
  {
    path: ROUTES.privacy,
    component: <Policies type={"policy"} />,
    title: "Privacy Policy | Pony",
    exact: true,
  },
  {
    path: ROUTES.terms,
    component: <Policies type={"terms"} />,
    title: "End User Licence Agreement | Pony",
    exact: true,
  },
  {
    path: ROUTES.support,
    component: <Support />,
    title: "Support | Pony",
    exact: true,
  },
  {
    path: ROUTES.login,
    component: <Login />,
    replace: true,
    title: "Login | Pony",
  },
  { path: ROUTES.default, component: <Landing />, replace: true },
  {
    path: ROUTES.share_situation,
    component: <SituationShare />,
    replace: true,
  },
  {
    path: ROUTES.share_dictionary,
    component: <DictionaryShare />,
    replace: true,
  },
  {
    path: ROUTES.follow_teacher,
    component: <FollowTeacher />,
    replace: true,
  },
];

const TEACHER_ROUTES = [
  {
    path: ROUTES.teacherTutorial,
    component: <TeachersView type={Views.Tutorial} />,
    exact: true,
    title: "Tutorial | Pony",
  },
  {
    path: ROUTES.teacherTracks,
    component: <TeachersView type={Chapters.Track} />,
    exact: true,
    title: "Tracks | Pony",
  },
  {
    path: ROUTES.teacherDictionary,
    component: <TeachersView type={Chapters.Dictionary} />,
    exact: true,
    title: "Dictionary | Pony",
  },
  {
    path: ROUTES.teacherSituations,
    component: <TeachersView type={Chapters.Situation} />,
    exact: true,
    title: "Situations | Pony",
  },
  {
    path: ROUTES.teacherExercises,
    component: <TeachersView type={Chapters.Exercise} />,
    exact: true,
    title: "Exercises | Pony",
  },
  {
    path: ROUTES.students,
    component: <TeachersView type={Views.Students} />,
    exact: true,
    title: "Students | Pony",
  },
  {
    path: ROUTES.teachers,
    component: <TeachersView type={Views.Teachers} />,
    exact: true,
    title: "Teachers | Pony",
  },
  {
    path: ROUTES.lessons,
    component: <TeachersView type={Views.Lessons} />,
    exact: true,
    title: "Lessons | Pony",
  },
  {
    path: ROUTES.profile,
    component: <Profile />,
    exact: true,
    title: "Profile | Pony",
  },
];

const MARKETING_ROUTES = [
  {
    path: ROUTES.users,
    component: <Users />,
    exact: true,
    title: "Users | Marketing",
  },
  {
    path: ROUTES.profile,
    component: <Profile />,
    exact: true,
    title: "Profile | Pony",
  },
];

export const getRoutesByRole = (role) => {
  switch (role) {
    case Roles.Admin:
      return ADMIN_ROUTES;
    case Roles.Teacher:
      return TEACHER_ROUTES;
    case Roles.Guest:
      return PUBLIC_ROUTES;
    case Roles.Marketing:
      return MARKETING_ROUTES;
    default:
      return [];
  }
};

export const getDefaultRouteByRole = (role) => {
  switch (role) {
    case Roles.Marketing:
      return ROUTES.users;
    case Roles.Admin:
      return ROUTES.library;
    case Roles.Teacher:
      return ROUTES.teacherTutorial;
    case Roles.Guest:
      return ROUTES.default;
    default:
      return ROUTES.default;
  }
};
