import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../../../hooks/useFetching";
import TrackService from "../../../../api/TrackService";
import exercisesStore from "../../store/exercisesStore";
import Player from "../../../Player/Player";
import { Chapters } from "../../../TeacherLessons/data/constants";
import {
  AddChapterModal,
  ChapterPlug,
  ChapterPreviewContainer,
  ChaptersScrollableList,
} from "..";
import {
  Checkbox,
  Input,
  Select,
  ToggleButton,
  Toolbar,
} from "../../../../teacherComponents";
import { LanguageLevels } from "../../../../data/common";
import { FilterModes, TrackPaginationLimit } from "../../data/constants";
import cl from "./SelectVideoModal.module.css";

const SelectVideoModal = ({
  visible,
  setVisible,
  onClick,
  withCategorySelector,
  alreadyAdded = [],
}) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const { difficulty: globalDifficulty, categoryOptions } = exercisesStore;

  const scrollRef = useRef(0);

  const [currentTrack, setCurrentTrack] = useState();
  const [mode, setMode] = useState(
    withCategorySelector ? FilterModes.Teacher : FilterModes.All
  );
  const [page, setPage] = useState(0);
  const [tracks, setTracks] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [difficulty, setDifficulty] = useState(globalDifficulty);
  const [paginationEnd, setPaginationEnd] = useState(false);
  const [processed, setProcessed] = useState(false);
  const [category, setCategory] = useState();
  const [ownDisabled, setOwnDisabled] = useState(false);

  const [getTracks, isLoading] = useFetching(async () => {
    const params = {
      offset: page * TrackPaginationLimit,
      limit: TrackPaginationLimit,
      lang,
      difficulty: difficulty ? LanguageLevels.indexOf(difficulty) : null,
      processed,
      searchString,
      recognized: true,
      categories: category ? [category] : null,
    };
    const { data } =
      mode === FilterModes.All
        ? await TrackService.getTracks(params)
        : await TrackService.getFavorites(params);
    handleTracksResponse(data);
  });

  const handleTracksResponse = (data) => {
    const { items } = data;
    if (!items.length) setPaginationEnd(true);
    const newTracks = (page > 0 ? [...tracks, ...items] : items).filter(
      (t, index, self) =>
        !self.slice(0, index).some((item) => item?.id === t?.id)
    );

    const withoutExisting = newTracks.filter(
      (e) => !alreadyAdded.some((a) => a?.id === e?.id)
    );
    setTracks(withoutExisting);
    setCurrentTrack(withoutExisting[0]);
  };

  const handleTrackListScroll = (e) => {
    const list = e.target;
    if (paginationEnd || !list) return;

    if (!isLoading && list.scrollTop > scrollRef.current) {
      const halfwayPoint = list.scrollHeight / 2;

      if (list.scrollTop + list.clientHeight >= halfwayPoint) {
        setPage(page + 1);
      }
    }

    scrollRef.current = list.scrollTop;
  };

  const handleTrackSelect = () => {
    if (!currentTrack) return;
    onClick(currentTrack?.id);
    setVisible(false);
  };

  useEffect(() => {
    getTracks();
  }, [page]);

  useEffect(() => {
    if (page === 0 && withCategorySelector && paginationEnd) {
      setMode(FilterModes.All);
      setOwnDisabled(true);
    }
  }, [page, withCategorySelector, paginationEnd]);

  useEffect(() => {
    setPage(0);
    setPaginationEnd(false);
    getTracks();
  }, [difficulty, lang, searchString, processed, mode, category]);

  return (
    <AddChapterModal
      visible={visible}
      setVisible={setVisible}
      type={Chapters.Track}
    >
      <Toolbar>
        <ToggleButton
          selectedOption={difficulty}
          setOption={setDifficulty}
          options={LanguageLevels}
          nullable
        />
        {withCategorySelector && (
          <ToggleButton
            selectedOption={mode}
            setOption={setMode}
            options={Object.values(FilterModes)}
            disabled
            availableOptions={
              ownDisabled ? FilterModes.All : Object.values(FilterModes)
            }
            withTranslations={"exercises"}
          />
        )}
        <Select
          label={t("tags_cont.choose_category")}
          style={{ minWidth: 220 }}
          variant={"white"}
          value={category}
          onChange={setCategory}
          options={
            category
              ? [{ value: "", name: t("podcasts.all") }, ...categoryOptions]
              : categoryOptions
          }
        />
        <Checkbox
          value={processed}
          onChange={setProcessed}
          label={t("exercises.processed_tracks")}
        />
        <Input
          variant={"outlinedSmall"}
          placeholder={t("library.search")}
          value={searchString}
          onChange={setSearchString}
        />
      </Toolbar>
      <div className={cl.tracksContainer}>
        <ChaptersScrollableList
          type={Chapters.Track}
          isLoading={isLoading}
          chapters={tracks}
          current={currentTrack}
          setCurrent={setCurrentTrack}
          onScroll={handleTrackListScroll}
          onSelect={handleTrackSelect}
        />
        {visible && currentTrack ? (
          <ChapterPreviewContainer>
            <Player track={currentTrack} />
          </ChapterPreviewContainer>
        ) : (
          <ChapterPlug />
        )}
      </div>
    </AddChapterModal>
  );
};

export default SelectVideoModal;
