import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../../../../../hooks/useFetching";
import SituationsService, {
  URL,
} from "../../../../../../api/SituationsService";
import { getAvailableOptions } from "../../../../../SituationsAdministration/helpers/getAvailableOptions";
import {
  GENDERS,
  MODALITIES,
} from "../../../../../SituationsAdministration/data/constants";
import Statements from "../Statements/Statements";
import { Button, ToggleButton } from "../../../../../../teacherComponents";
import { ReactComponent as IconHappy } from "../../../../../../assets/svg/lessons-happy.svg";
import { ReactComponent as IconNeutral } from "../../../../../../assets/svg/lessons-neutral.svg";
import { ReactComponent as IconSad } from "../../../../../../assets/svg/lessons-sad.svg";
import { ReactComponent as IconSound } from "../../../../../../assets/img/icon-sound_alt.svg";
import cl from "./Situation.module.css";

const Situation = ({ situation, activeLang }) => {
  const { i18n } = useTranslation();
  const lang = activeLang ?? i18n.language;

  const { id } = situation;

  const [situationData, setSituationData] = useState({});
  const [gender, setGender] = useState();
  const [modality, setModality] = useState();
  const [availableOptions, setAvailableOptions] = useState({});
  const [audio] = useState(new Audio());
  const [currentPlaying, setCurrentPlaying] = useState("");
  const [isPlay, setIsPlay] = useState(false);

  const [getSituation, isSituationLoading, isEmpty, resetEmpty] = useFetching(
    async ({ gender, modality }) => {
      const { data } = await SituationsService.getSituation({
        id,
        language: lang.toUpperCase(),
        gender,
        modality,
        targetLanguage: lang.toUpperCase(),
      });
      resetEmpty();
      const { statements, info } = data;
      setSituationData({
        ...situation,
        statements,
        situationInfoId: info.id,
        processed: info.processed,
      });
    }
  );

  const [getSituationInfo] = useFetching(async () => {
    const { data } = await SituationsService.getSituationInfo({
      id,
      language: lang.toUpperCase(),
    });
    const { info } = data;
    setAvailableOptions(info?.length ? getAvailableOptions(info) : {});
    setGender(info[0]?.gender || GENDERS[0]);
    setModality(info[0]?.modality || MODALITIES[0]);
  });

  const handleGenderChange = (gender) => {
    if (
      availableOptions[gender] &&
      Object.keys(availableOptions[gender])?.length > 0
    ) {
      handleModalityChange(Object.keys(availableOptions[gender])[0]);
    }
    setGender(gender);
  };

  const handleModalityChange = (modality) => {
    setModality(modality);
  };

  const getAvailableGenders = () => Object.keys(availableOptions) || [];

  const getAvailableModalities = () => {
    if (availableOptions[gender]) {
      return Object.keys(availableOptions[gender]) || [];
    } else {
      return [];
    }
  };

  const handleStatementsPlay = () => setIsPlay(!isPlay);

  useEffect(() => {
    if (!situationData || !situationData.statements) return;

    const { statements } = situationData;
    let index = 0;

    const playNextAudio = () => {
      if (index >= statements.length) return;
      const statement = statements[index];
      if (!statement.phraseVoice) {
        index++;
        setTimeout(() => {
          playNextAudio();
        }, 100);
        return;
      }
      audio.src = `${URL}${statement.phraseVoice}`;
      setCurrentPlaying(statement.id);

      audio.play();
    };

    const playNextCallback = () => {
      index++;
      setTimeout(() => {
        playNextAudio();
      }, 100);
    };

    audio.addEventListener("ended", playNextCallback);
    if (isPlay) {
      playNextAudio();
    } else {
      audio.removeEventListener("ended", playNextCallback);
      audio.src = "";
      setCurrentPlaying("");
      audio.pause();
    }

    return () => {
      audio.removeEventListener("ended", playNextCallback);
    };
  }, [isPlay]);

  useEffect(() => {
    audio.src = "";
    audio.pause();
    setCurrentPlaying("");
    setIsPlay(false);
  }, [situation, lang]);

  useEffect(
    () => () => {
      audio.src = "";
      audio.pause();
      setIsPlay(false);
      setCurrentPlaying("");
    },
    []
  );

  useEffect(() => {
    if (gender && modality) {
      getSituation({ gender, modality });
    }
  }, [situation, gender, modality]);

  useEffect(() => {
    if (!situation.info) {
      getSituationInfo();
    } else {
      setAvailableOptions(
        situation.info?.length ? getAvailableOptions(situation.info) : {}
      );
      setGender(situation.info[0]?.gender || GENDERS[0]);
      setModality(situation.info[0]?.modality || MODALITIES[0]);
    }
  }, [situation]);

  useEffect(() => {
    if (isEmpty) setSituationData({});
  }, [isEmpty]);

  return (
    <div className={cl.situation}>
      <p className={cl.situationTitle}>{situation.title}</p>
      <div className={cl.infoBlock}>
        <ToggleButton
          options={GENDERS}
          availableOptions={getAvailableGenders()}
          selectedOption={gender}
          setOption={handleGenderChange}
          withTranslations={"situations"}
          variant={"large"}
          isGrid
        />
        <ToggleButton
          options={MODALITIES}
          icons={[
            <IconHappy className={cl.happy} />,
            <IconNeutral className={cl.neutral} />,
            <IconSad className={cl.sad} />,
          ]}
          availableOptions={getAvailableModalities()}
          selectedOption={modality}
          setOption={handleModalityChange}
          withTranslations={"situations"}
          variant={"large"}
          isGrid
          disabled
        />
        <Button
          onClick={handleStatementsPlay}
          style={{
            width: 32,
            height: 32,
            minWidth: 32,
            padding: 0,
            borderRadius: 8,
          }}
          variant={isPlay ? "dark" : "white"}
          icon={
            <IconSound
              className={`${cl.buttonIcon} ${isPlay ? "" : cl.dark}`}
            />
          }
        />
      </div>
      <Statements
        situation={situationData}
        isEmpty={isEmpty}
        isLoading={isSituationLoading}
        currentPlaying={currentPlaying}
      />
    </div>
  );
};

export default observer(Situation);
