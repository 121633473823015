import { googleLngList } from "../../../data/languagesList";
import { LanguageLevels } from "../../../data/common";

export const UsersPerPage = 50;

export const AllLanguages = [
  ...Object.keys(googleLngList).map((lngCode) => ({
    value: lngCode,
    name: googleLngList[lngCode],
  })),
];

export const LanguageOptions = LanguageLevels.map((l, i) => ({
  value: i,
  name: l,
}));

export const SortOrder = {
  Asc: "asc",
  Desc: "desc",
};

export const DefaultSort = {
  Column: "last_seen",
  Order: SortOrder.Desc,
};

export const SortColumns = [
  "email",
  "lang",
  "name",
  "created_at",
  "updated_at",
  "last_seen",
];
