import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import lessonsStore from "../../../TeacherLessons/store/lessonsStore";
import { UserChapterCard } from "..";
import { Chapters } from "../../../TeacherLessons/data/constants";
import { ReactComponent as IconDictionary } from "../../../../assets/svg/lessons-dictionary.svg";
import { ReactComponent as IconSituation } from "../../../../assets/svg/lessons-situation.svg";
import { ReactComponent as IconTrack } from "../../../../assets/svg/lessons-track.svg";
import { ReactComponent as IconArrow } from "../../../../assets/svg/lessons-arrow.svg";
import cl from "./UserChapterSection.module.css";

const UserChapterSection = ({ chapters, lessonId, type, onDelete }) => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(true);

  const { currentChapter } = lessonsStore;

  const toggleExpanded = () => setIsExpanded(!isExpanded);

  const renderIcon = () => {
    switch (type) {
      case Chapters.Track:
        return <IconTrack />;
      case Chapters.Situation:
        return <IconSituation />;
      case Chapters.Dictionary:
      case "words_list":
        return <IconDictionary />;
      default:
        return null;
    }
  };

  const renderChapters = () => {
    if (!isExpanded) return null;
    if (type === "words_list")
      return (
        <div className={cl.words}>
          {chapters.map((w, i) => (
            <p className={`${cl.word} ${cl[w.status]}`} key={i}>
              {w.word}
            </p>
          ))}
        </div>
      );

    return chapters.map((chapter) => (
      <UserChapterCard
        key={chapter.id}
        type={type}
        chapter={chapter}
        onDelete={onDelete}
        isActive={currentChapter?.id === chapter.id}
        setActive={lessonsStore.setCurrentChapter}
        lessonId={lessonId}
      />
    ));
  };

  useEffect(() => {
    setIsExpanded(true);
  }, []);

  if (chapters.length === 0) return null;

  return (
    <div className={cl.chapterContainer}>
      <div className={cl.chapterTypeCont} onClick={toggleExpanded}>
        {renderIcon()}
        <p className={cl.chapterType}>{`${t(`exercises.${type}_view`)} ${
          !isExpanded ? `(${chapters.length})` : ""
        }`}</p>

        <div
          className={cl.control}
          style={{ transform: !isExpanded ? "rotate(180deg)" : "" }}
        >
          <IconArrow />
        </div>
      </div>
      {renderChapters()}
    </div>
  );
};

export default observer(UserChapterSection);
