import React, { useEffect, useRef } from "react";
import { ReactComponent as IconWarning } from "../../assets/svg/lessons-warning.svg";
import cl from "./Tooltip.module.css";

const Tooltip = ({ text, variant, tailPosition, style, onHide }) => {
  const ref = useRef();

  const getIcon = () => {
    switch (variant) {
      case "warning":
        return <IconWarning className={cl.warningIcon} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        onHide();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div
      ref={ref}
      className={`${cl.tooltip} ${cl[variant]} ${cl[tailPosition]}`}
      style={style}
    >
      {getIcon()}
      <span>{text}</span>
    </div>
  );
};

export default Tooltip;
