import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../../../../../hooks/useFetching";
import ProgressService from "../../../../../../api/ProgressService";
import { Input, Toolbar } from "../../../../../../teacherComponents";
import { Chapters } from "../../../../../TeacherLessons/data/constants";
import {
  AddChapterModal,
  ChapterPlug,
  ChapterPreviewContainer,
  ChaptersScrollableList,
} from "../../../../components";
import cl from "./SelectDictionaryModal.module.css";
import CategoryWords from "../CategoryWords/CategoryWords";

const SelectDictionaryModal = ({
  visible,
  setVisible,
  onClick,
  alreadyAdded = [],
}) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const [currentWord, setCurrentWord] = useState();
  const [currentCategory, setCurrentCategory] = useState();
  const [categories, setCategories] = useState([]);
  const [searchString, setSearchString] = useState("");

  const [getCategories, isLoading] = useFetching(async () => {
    const { data: categoriesData } = await ProgressService.getWordsCategories();

    const categoriesWithWords = await Promise.all(
      categoriesData.items.map(async (category) => {
        const { data: categoryWordsData } =
          await ProgressService.getCategoryWords({
            categoryId: category.id,
            lang,
          });

        return {
          ...category,
          words: categoryWordsData.items,
        };
      })
    );

    const withoutExisting = categoriesWithWords.filter(
      (e) => !alreadyAdded.some((a) => a.id === e.id)
    );

    setCategories(withoutExisting);
    if (!currentCategory) setCurrentCategory(withoutExisting[0]);
  });

  const handleCategorySelet = () => {
    if (!currentCategory) return;
    onClick(currentCategory.id);
    setVisible(false);
  };

  useEffect(() => {
    getCategories();
    setCurrentWord();
  }, [lang, searchString]);

  return (
    <AddChapterModal
      visible={visible}
      setVisible={setVisible}
      type={Chapters.Dictionary}
    >
      <Toolbar>
        <Input
          variant={"outlinedSmall"}
          placeholder={t("library.search")}
          value={searchString}
          onChange={setSearchString}
        />
      </Toolbar>
      <div className={cl.tracksContainer}>
        <ChaptersScrollableList
          type={Chapters.Dictionary}
          isLoading={isLoading}
          chapters={categories}
          current={currentCategory}
          setCurrent={setCurrentCategory}
          onSelect={handleCategorySelet}
          onScroll={() => {}}
        />
        {currentCategory ? (
          <ChapterPreviewContainer>
            <CategoryWords
              category={currentCategory}
              lang={lang}
              currentWord={currentWord}
              setCurrentWord={setCurrentWord}
            />
          </ChapterPreviewContainer>
        ) : (
          <ChapterPlug />
        )}
      </div>
    </AddChapterModal>
  );
};

export default SelectDictionaryModal;
