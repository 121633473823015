import React from "react";
import { LanguageLevels } from "../../../../data/common";
import cl from "./UserItem.module.css";

const UserItem = ({ user, onClick }) => {
  const { email, name, lang, nativeLang, level, id, lastSeen } = user;

  const formatLastSeen = (lastSeen) => {
    if (!lastSeen) return "-";
    const date = new Date(lastSeen);

    const pad = (num) => (num < 10 ? "0" + num : num);

    const hours = pad(date.getUTCHours());
    const minutes = pad(date.getUTCMinutes());
    const seconds = pad(date.getUTCSeconds());
    const day = pad(date.getUTCDate());
    const month = pad(date.getUTCMonth() + 1);
    const year = date.getUTCFullYear();

    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <div className={cl.userItem}>
      <div className={cl.userCell} onClick={() => onClick(id)}>
        {email}
      </div>
      <div className={cl.userCell}>{formatLastSeen(lastSeen)}</div>
      <div className={cl.userCell}>{name || "-"}</div>
      <div className={cl.userCell}>{lang || "-"}</div>
      <div className={cl.userCell}>{nativeLang || "-"}</div>
      <div className={cl.userCell}>
        {typeof level === "number" ? LanguageLevels[level] : "-"}
      </div>
    </div>
  );
};

export default UserItem;
