import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../../../hooks/useFetching";
import SituationsService from "../../../../api/SituationsService";
import DemoPageLoader from "../../../../components/UI/DemoPageLoader/DemoPageLoader";
import { Chapters } from "../../../TeacherLessons/data/constants";
import { mapChapterData } from "../../../TeacherContent/helpers/mapChapterData";
import cl from "./UserChapterCard.module.css";

const FinishValues = {
  TrackFinished: 0.8,
  SituationFinished: 4,
};

const UserChapterCard = ({
  type,
  chapter,
  isActive,
  setActive,
  lessonId,
  isScrollable,
}) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const [mappedChapter, setMappedChapter] = useState({});
  const [situationInfo, setSituationInfo] = useState();
  const [isLoaded, setIsLoaded] = useState(false);

  const { title, preview, params = [] } = mappedChapter;

  const [getSituationInfo, infoLoading] = useFetching(async () => {
    const { data } = await SituationsService.getSituationInfo({
      id: chapter.id,
      language: lang.toUpperCase(),
    });
    setSituationInfo(data?.info ?? []);
  });

  const handleChapterClick = () => {
    if (!setActive || isActive) return;
    setActive(
      type === Chapters.Situation
        ? { ...chapter, info: chapter.info ?? situationInfo }
        : chapter
    );
  };

  const isFinished = () => {
    switch (type) {
      case Chapters.Track:
        return chapter.progress > FinishValues.TrackFinished;
      case Chapters.Situation:
        const progress = [
          chapter.listeningCompleted,
          chapter.puzzleCompleted,
          chapter.speakingCompleted,
          chapter.translationCompleted,
        ].map((p) => (typeof p === "number" ? p : 0));
        return (
          progress.reduce((a, b) => a + b) === FinishValues.SituationFinished
        );
      default:
        return false;
    }
  };

  const renderProgress = () => {
    switch (type) {
      case Chapters.Track:
        return (
          <div className={cl.trackProgress}>
            <div
              className={cl.progressFill}
              style={{ width: `${(1 - chapter.progress) * 100}%` }}
            />
          </div>
        );
      case Chapters.Situation:
        const progress = [
          chapter.listeningCompleted,
          chapter.puzzleCompleted,
          chapter.speakingCompleted,
          chapter.translationCompleted,
        ].map((p) => (typeof p === "number" ? p : 0));
        const isFinished = progress.reduce((a, b) => a + b) === 4;
        return progress.map((p, i) => (
          <div
            className={`${cl.situationProgress} ${
              isFinished ? cl.finished : ""
            }`}
            key={i}
          >
            <div
              className={cl.progressFill}
              style={{
                width: `${(1 - p) * 100}%`,
              }}
            />
          </div>
        ));

      case Chapters.Dictionary:
        return <></>;
      case Chapters.File:
      default:
        return null;
    }
  };

  useEffect(() => {
    if (type === Chapters.Situation && !chapter.info) {
      getSituationInfo();
    } else {
      setSituationInfo();
    }
    setMappedChapter(mapChapterData({ ...chapter }, type, t));
  }, [chapter]);

  return (
    <div
      className={`${cl.chapterCard} ${isActive ? cl.active : ""} ${
        lessonId ? cl.lesson : ""
      } ${cl[type]} ${isScrollable ? cl.scrollable : ""}`}
      onClick={handleChapterClick}
    >
      <div
        className={`${cl.imageContainer} ${isLoaded ? cl.loaded : ""} ${
          isFinished() ? cl.finished : ""
        }`}
      >
        {preview && (
          <>
            {!isLoaded && <DemoPageLoader size={12} color={"#ffffff"} />}
            <img src={preview} alt={title} onLoad={() => setIsLoaded(true)} />
          </>
        )}
      </div>

      <div className={cl.cardInner}>
        <p className={cl.title}>{title}</p>
        <div className={cl.infoContainer}>
          {params.map((p, i) => (
            <p className={cl.label} key={i}>
              {p}
            </p>
          ))}
        </div>
      </div>

      <div className={cl.progressContainer}>{renderProgress()}</div>
    </div>
  );
};

export default observer(UserChapterCard);
