import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../../../../../hooks/useFetching";
import ProgressService from "../../../../../../api/ProgressService";
import MyModal from "../../../../../../components/UI/MyModal/MyModal";
import ExerciseService from "../../../../../../api/ExerciseService";
import { DefaultWordsParams, WordsRange } from "../../data/constants";
import {
  Button,
  Input,
  Label,
  Range,
  ToggleButton,
} from "../../../../../../teacherComponents";
import { LanguageLevels } from "../../../../../../data/common";
import cl from "./GenerateWordsCategoryModal.module.css";

const GenerateWordsCategoryModal = ({ lang, visible, setVisible, onAdd }) => {
  const { t } = useTranslation();

  const [wordsParams, setWordsParams] = useState(DefaultWordsParams);
  const [formErrors, setFormErrors] = useState({});

  const [generateWords, generaeLoading, generateError, resetGenerateError] =
    useFetching(async () => {
      const { topic, level, title, wordsAmount } = wordsParams;
      const { data } = await ExerciseService.generateWords({
        topic,
        difficulty: LanguageLevels.indexOf(level),
        lang,
        wordsAmount,
      });
      const { words } = data;
      createCategory({ words, title, level });
    });

  const [createCategory, createLoading] = useFetching(
    async ({ words, title, level }) => {
      const { data } = await ProgressService.createWordsCategory({
        title,
        lang,
        level: LanguageLevels.indexOf(level),
      });
      setVisible(false);
      onAdd(data, words);
    }
  );

  const setWordsCount = (e) => {
    setWordsParams({ ...wordsParams, wordsAmount: e.target.value });
  };

  const handleWordsGenerate = () => {
    resetErrors();

    setTimeout(() => {
      const errors = {
        topic: !wordsParams.topic,
        title: !wordsParams.title,
        level: !wordsParams.level,
      };
      setFormErrors(errors);

      if (Object.values(errors).some((e) => e)) return;

      generateWords();
    });
  };

  const isLoading = createLoading || generaeLoading;

  const resetErrors = () => {
    resetGenerateError();
    setFormErrors({});
  };

  useEffect(() => {
    setWordsParams(DefaultWordsParams);
  }, [visible]);

  useEffect(() => {}, [wordsParams]);

  return (
    <MyModal
      className={cl.generateModal}
      visible={visible}
      setVisible={setVisible}
    >
      <p className={cl.title}>{t("exercises.generate_words_pack")}</p>
      <p className={cl.subtitle}>{t("exercises.generate_words_pack_desc")}</p>
      <div>
        <Label
          text={t("exercises.words_category_title")}
          isError={formErrors["title"]}
        />
        <Input
          variant={formErrors["title"] ? "erroredSmall" : "outlinedSmall"}
          placeholder={t("exercises.words_category_title_placeholder")}
          value={wordsParams.title}
          onChange={(title) => setWordsParams({ ...wordsParams, title })}
        />
      </div>
      <div>
        <Label
          text={t("exercises.exercise_level")}
          isError={formErrors["level"]}
        />
        <ToggleButton
          style={{ width: 212 }}
          label={t("users.level")}
          options={LanguageLevels}
          selectedOption={wordsParams.level}
          variant={formErrors["level"] ? "error" : "outlined"}
          setOption={(level) => setWordsParams({ ...wordsParams, level })}
        />
      </div>
      <div>
        <Label text={t("exercises.topic")} isError={formErrors["topic"]} />
        <Input
          variant={formErrors["topic"] ? "erroredSmall" : "outlinedSmall"}
          placeholder={t("exercises.topic_example")}
          value={wordsParams.topic}
          onChange={(topic) => setWordsParams({ ...wordsParams, topic })}
        />
      </div>

      <p className={cl.rangeLabel}>{`${t("exercises.words_amount")} (${
        wordsParams.wordsAmount ?? DefaultWordsParams.wordsAmount
      })`}</p>
      <Range
        value={wordsParams.wordsAmount}
        min={WordsRange.Min}
        step={WordsRange.Step}
        max={WordsRange.Max}
        onChange={setWordsCount}
      />
      {generateError && (
        <p className={cl.error}>{t("errors.unexpected_error")}</p>
      )}
      <Button
        style={{ margin: "auto" }}
        text={t("exercises.generate_words")}
        variant={"purple"}
        onClick={handleWordsGenerate}
        isLoading={isLoading}
      />
    </MyModal>
  );
};

export default GenerateWordsCategoryModal;
