import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectVideoModal } from "../../../../../TeacherContent/components";
import { SelectExerciseModal } from "../../../../../TeacherContent/views/ExercisesView/components";
import { SelectSituationModal } from "../../../../../TeacherContent/views/SituationsView/components";
import { SelectDictionaryModal } from "../../../../../TeacherContent/views/DictionaryView/components";
import { Tooltip } from "../../../../../../teacherComponents";
import FileUploader from "../../../../../../components/UI/FileUploader/FileUploader";
import { Chapters } from "../../../../data/constants";
import DemoPageLoader from "../../../../../../components/UI/DemoPageLoader/DemoPageLoader";
import { ReactComponent as IconDictionary } from "../../../../../../assets/svg/lessons-dictionary.svg";
import { ReactComponent as IconExercise } from "../../../../../../assets/svg/lessons-exercise.svg";
import { ReactComponent as IconSituation } from "../../../../../../assets/svg/lessons-situation.svg";
import { ReactComponent as IconTrack } from "../../../../../../assets/svg/lessons-track.svg";
import { ReactComponent as IconFile } from "../../../../../../assets/svg/lessons-file.svg";
import cl from "./AddChapter.module.css";

const AddChapter = ({
  lang,
  onAdd,
  alreadyAdded,
  onFileSelect,
  isBlocked,
  isLoading,
  isError,
  resetError,
}) => {
  const { t } = useTranslation();
  const ref = useRef();

  const { dictionaries, exercises, situations, tracks } = alreadyAdded;
  const [isExpanded, setIsExpanded] = useState(false);

  const [currentType, setCurrentType] = useState("");

  const renderIcon = (type) => {
    switch (type) {
      case Chapters.Track:
        return <IconTrack />;
      case Chapters.Situation:
        return <IconSituation />;
      case Chapters.Exercise:
        return <IconExercise />;
      case Chapters.Dictionary:
        return <IconDictionary />;
      case Chapters.File:
        return <IconFile />;
      default:
        return null;
    }
  };

  const getClassName = () => {
    const classNames = [cl.buttonContainer];
    if (isBlocked) classNames.push(cl.blocked);
    if (isExpanded) classNames.push(cl.expanded);

    return classNames.join(" ");
  };

  const renderNewChapterSelect = () => {
    switch (currentType) {
      case Chapters.Track:
        return (
          <SelectVideoModal
            visible={currentType === Chapters.Track}
            setVisible={() => setCurrentType("")}
            onClick={(id) => {
              onAdd({ chapterId: id, chapterType: Chapters.Track });
            }}
            alreadyAdded={tracks}
            withCategorySelector={true}
          />
        );
      case Chapters.Exercise:
        return (
          <SelectExerciseModal
            visible={currentType === Chapters.Exercise}
            setVisible={() => setCurrentType("")}
            onClick={(id) => {
              onAdd({ chapterId: id, chapterType: Chapters.Exercise });
            }}
            alreadyAdded={exercises}
            withCategorySelector={true}
          />
        );
      case Chapters.Situation:
        return (
          <SelectSituationModal
            visible={currentType === Chapters.Situation}
            setVisible={() => setCurrentType("")}
            onClick={(id) => {
              onAdd({ chapterId: id, chapterType: Chapters.Situation });
            }}
            alreadyAdded={situations}
            withCategorySelector={true}
          />
        );
      case Chapters.Dictionary:
        return (
          <SelectDictionaryModal
            visible={currentType === Chapters.Dictionary}
            setVisible={() => setCurrentType("")}
            onClick={(id) => {
              onAdd({ chapterId: id, chapterType: Chapters.Dictionary });
            }}
            lang={lang}
            alreadyAdded={dictionaries}
            withCategorySelector={true}
          />
        );
      case Chapters.File:
        return (
          <FileUploader
            type={"pdf"}
            inputKey={"pdfUploader"}
            onSave={onFileSelect}
            visible={currentType === Chapters.File}
            setVisible={() => setCurrentType("")}
            isLessons
          />
        );
      default:
        return <></>;
    }
  };

  const handleAddButtonClick = () => {
    setIsExpanded(true);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsExpanded(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <>
      {renderNewChapterSelect()}
      <div ref={ref} className={getClassName()} onClick={handleAddButtonClick}>
        {isLoading ? (
          <DemoPageLoader size={24} color={"#ffffff"} />
        ) : (
          t("exercises.add_chapter")
        )}
        {isBlocked && isExpanded && (
          <Tooltip
            variant={"warning"}
            text={t("exercises.unselect_student_lesson")}
            onHide={() => setIsExpanded(false)}
            tailPosition="center"
            style={{
              top: -45,
              left: "50%",
              transform: "translateX(-50%)",
            }}
          />
        )}
        {!isLoading && isError && (
          <Tooltip
            variant={"error"}
            text={t("exercises.chapter_conflict")}
            onHide={resetError}
            tailPosition="center"
            style={{
              top: -45,
              left: "50%",
              transform: "translateX(-50%)",
            }}
          />
        )}

        <div className={cl.buttons}>
          {Object.values(Chapters).map((type, i) => (
            <div
              className={`${cl.button} ${cl[type]}`}
              key={i}
              onClick={() => {
                setCurrentType(type);
              }}
            >
              {renderIcon(type)}
              {t(`exercises.single_${type}`)}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AddChapter;
