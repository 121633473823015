import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import exercisesStore from "../../../../store/exercisesStore";
import { useFetching } from "../../../../../../hooks/useFetching";
import ProgressService from "../../../../../../api/ProgressService";
import SituationsService from "../../../../../../api/SituationsService";
import {
  Input,
  Select,
  ToggleButton,
  Toolbar,
} from "../../../../../../teacherComponents";
import { LanguageLevels } from "../../../../../../data/common";
import Situation from "../Situation/Situation";
import { FilterModes } from "../../../../data/constants";
import {
  AddChapterModal,
  ChapterPlug,
  ChapterPreviewContainer,
  ChaptersScrollableList,
} from "../../../../components";
import { Chapters } from "../../../../../TeacherLessons/data/constants";
import cl from "./SelectSituationModal.module.css";

const SelectSituationModal = ({
  visible,
  setVisible,
  onClick,
  withCategorySelector,
  alreadyAdded = [],
}) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const { situationCategoryOptions } = exercisesStore;

  const [mode, setMode] = useState(
    withCategorySelector ? FilterModes.Teacher : FilterModes.All
  );
  const [situations, setSituations] = useState([]);
  const [filteredSituations, setFilteredSituations] = useState([]);
  const [difficulty, setDifficulty] = useState();
  const [searchString, setSearchString] = useState();
  const [categoryId, setCategoryId] = useState();
  const [currentSituation, setCurrentSituation] = useState();
  const [paginationEnd, setPaginationEnd] = useState(false);
  const [ownDisabled, setOwnDisabled] = useState(false);

  const [getAllSituations, allLoading] = useFetching(async () => {
    if (!visible) return;
    const { data } = await SituationsService.getAvailableCategories({
      language: lang.toUpperCase(),
      level: difficulty,
    });

    const allSituations = data.map((d) => d.situations).flat();
    const withoutExisting = allSituations.filter(
      (e) => !alreadyAdded.some((a) => a.id === e.id)
    );
    setSituations(withoutExisting);

    if (!currentSituation) setCurrentSituation(withoutExisting[0]);
  });

  const [getFavSituations, favLoading] = useFetching(async () => {
    const { data } = await ProgressService.getSituations({
      lang,
    });
    if (!data.items || !data.items.length) setPaginationEnd(true);
    const ids = data.items.map((s) => s.situationId);

    const { data: categories } = await SituationsService.getAvailableCategories(
      {
        language: lang.toUpperCase(),
      }
    );

    const situations = categories
      .map((c) => c.situations)
      .flat()
      .filter((s) => ids.includes(s.id));

    setSituations(situations);
    if (!currentSituation) setCurrentSituation(situations[0]);
  });

  const handleSituationSelect = () => {
    if (!currentSituation) return;

    onClick(currentSituation.id);
    setVisible(false);
  };

  useEffect(() => {
    setPaginationEnd(false);
    mode === FilterModes.All ? getAllSituations() : getFavSituations();
  }, [mode, visible, lang, difficulty]);

  useEffect(() => {
    if (withCategorySelector && paginationEnd) {
      setMode(FilterModes.All);
      setOwnDisabled(true);
    }
  }, [withCategorySelector, paginationEnd]);

  useEffect(() => {
    setCategoryId();
    setDifficulty();
    setSearchString();
    setCurrentSituation();
  }, [visible]);

  useEffect(() => {
    const filteredSituations = situations.filter((s) => {
      const matchesTitle = searchString
        ? s.title.toLowerCase().includes(searchString.toLowerCase())
        : true;

      const matchesCategory = categoryId ? s.categoryId === categoryId : true;

      return matchesTitle && matchesCategory;
    });

    setFilteredSituations(filteredSituations);
  }, [searchString, situations, categoryId]);

  return (
    <AddChapterModal
      visible={visible}
      setVisible={setVisible}
      type={Chapters.Situation}
    >
      <Toolbar>
        <ToggleButton
          selectedOption={difficulty}
          setOption={setDifficulty}
          options={LanguageLevels}
          nullable
        />
        {withCategorySelector && (
          <ToggleButton
            selectedOption={mode}
            setOption={setMode}
            disabled
            availableOptions={
              ownDisabled ? FilterModes.All : Object.values(FilterModes)
            }
            options={Object.values(FilterModes)}
            withTranslations={"exercises"}
          />
        )}
        <Select
          label={t("tags_cont.choose_category")}
          style={{ minWidth: 220 }}
          variant={"white"}
          value={categoryId}
          onChange={setCategoryId}
          options={
            categoryId
              ? [
                  { value: "", name: t("podcasts.all") },
                  ...situationCategoryOptions,
                ]
              : situationCategoryOptions
          }
        />
        <Input
          variant={"outlinedSmall"}
          placeholder={t("library.search")}
          value={searchString}
          onChange={setSearchString}
        />
      </Toolbar>
      <div className={cl.situationsContainer}>
        <ChaptersScrollableList
          type={Chapters.Situation}
          isLoading={allLoading || favLoading}
          chapters={filteredSituations}
          current={currentSituation}
          setCurrent={setCurrentSituation}
          onScroll={() => {}}
          onSelect={handleSituationSelect}
        />
        {currentSituation ? (
          <ChapterPreviewContainer>
            <Situation situation={currentSituation} />
          </ChapterPreviewContainer>
        ) : (
          <ChapterPlug />
        )}
      </div>
    </AddChapterModal>
  );
};

export default observer(SelectSituationModal);
