import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import exercisesStore from "../../store/exercisesStore";
import { useFetching } from "../../../../hooks/useFetching";
import ExerciseService from "../../../../api/ExerciseService";
import {
  EditExerciseModal,
  GenerateExerciseModal,
  Preview,
  SelectExerciseModal,
} from "./components";
import {
  ChapterPlug,
  ChapterPreviewContainer,
  ChaptersList,
  ContentContainer,
} from "../../components";
import { LanguageLevels } from "../../../../data/common";
import {
  ChapterModals,
  Chapters,
} from "../../../TeacherLessons/data/constants";

const ExercisesView = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language;

  const {
    currentExercise,
    chaptersModalType,
    difficulty,
    exercises,
    exerciseSearch,
    type,
    unassigned,
  } = exercisesStore;

  const [isFilter, setIsFilter] = useState(false);

  const [getExercises, isLoading] = useFetching(async () => {
    const { data } = await ExerciseService.getSavedExercises({
      offset: 0,
      limit: 500,
      lang,
      type,
      unassigned,
      difficulty: difficulty && LanguageLevels.indexOf(difficulty),
      searchString: exerciseSearch,
    });
    const exercises = data?.items ?? [];
    exercisesStore.setExercises(exercises);
    exercisesStore.setCurrentExercise(exercises[0]);
  });

  const [addExercise, addLoading] = useFetching(async (id) => {
    const existing = exercises.find((e) => e.id === id);
    if (existing) {
      exercisesStore.setChaptersModalType("");
      setTimeout(() => {
        exercisesStore.setCurrentExercise(existing);
      }, 300);

      return;
    }
    await ExerciseService.keepExercise({ id });
    const { data: exerciseResponse } = await ExerciseService.getExercise({
      id,
    });
    exercisesStore.setChaptersModalType("");
    exercisesStore.setExercises([exerciseResponse, ...exercises]);

    setTimeout(() => {
      exercisesStore.setCurrentExercise(exerciseResponse);
    }, 300);
  });

  const handleExerciseDelete = (id) => {
    exercisesStore.setExercises(exercises.filter((e) => e.id !== id));
  };

  const isRenderPreview =
    currentExercise &&
    chaptersModalType !== Chapters.Exercise &&
    chaptersModalType !== ChapterModals[Chapters.Exercise];

  useEffect(() => {
    exercisesStore.setCurrentExercise();
    getExercises();
    setIsFilter(type || exerciseSearch || difficulty);
  }, [lang, type, exerciseSearch, difficulty, unassigned]);

  return (
    <ContentContainer view={Chapters.Exercise}>
      <ChaptersList
        type={Chapters.Exercise}
        isLoading={isLoading}
        chapters={exercises}
        onDelete={handleExerciseDelete}
        current={currentExercise}
        setCurrent={exercisesStore.setCurrentExercise}
        isFilter={isFilter}
      />
      {isRenderPreview ? (
        <ChapterPreviewContainer>
          <Preview exerciseId={currentExercise.id} />
        </ChapterPreviewContainer>
      ) : (
        <ChapterPlug />
      )}
      {currentExercise && <EditExerciseModal />}
      <SelectExerciseModal
        visible={chaptersModalType === Chapters.Exercise}
        setVisible={exercisesStore.setChaptersModalType}
        onClick={addExercise}
        isLoading={addLoading}
        alreadyAdded={exercises}
      />
      <GenerateExerciseModal
        visible={chaptersModalType === ChapterModals[Chapters.Exercise]}
        setVisible={exercisesStore.setChaptersModalType}
      />
    </ContentContainer>
  );
};

export default observer(ExercisesView);
