import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import {
  Button,
  Input,
  Label,
  Range,
  Select,
  ToggleButton,
} from "../../../../../../teacherComponents";
import MyAdjustableTextarea from "../../../../../../components/UI/MyAdjustableTextarea/MyAdjustableTextarea";
import { useFetching } from "../../../../../../hooks/useFetching";
import ExerciseService from "../../../../../../api/ExerciseService";
import exercisesStore from "../../../../store/exercisesStore";
import {
  MediaTypes,
  TextRange,
  TextGenres,
  TextModes,
} from "../../../../data/constants";
import { LanguageLevels } from "../../../../../../data/common";
import SelectTargetVocabulary from "../SelectTargetVocabulary/SelectTargetVocabulary";
import { ReactComponent as IconStars } from "../../../../../../assets/svg/lessons-stars.svg";
import { ReactComponent as IconText } from "../../../../../../assets/svg/lessons-text_alt.svg";

import cl from "./GenerateText.module.css";

const DefaultTextParams = {
  level: "",
  vocabulary: "",
  genre: TextGenres.Text,
  grammarTopic: "",
  textTopic: "",
  sentencesAmount: 10,
};

const GenerateText = ({ mediaType, allowEmptyGrammar }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const [modeSelect, setModeSelect] = useState(true);
  const [textParams, setTextParams] = useState(DefaultTextParams);
  const [formErrors, setFormErrors] = useState({});
  const [vocabularyModal, setVocabularyModal] = useState(false);

  const [generateText, generateLoading] = useFetching(async () => {
    const {
      level,
      grammarTopic,
      textTopic,
      genre,
      vocabulary,
      sentencesAmount,
    } = textParams;
    const { data } = await ExerciseService.generateText({
      lang,
      difficulty: LanguageLevels.indexOf(level),
      grammarTopic,
      textTopic,
      genre,
      vocabulary: vocabulary.split(/ .,!?/),
      sentencesAmount,
    });

    exercisesStore.setGeneratedText(data.text);
  });

  const setSentencesAmount = (e) => {
    setTextParams({ ...textParams, sentencesAmount: e.target.value });
  };

  const handleTextGenerate = () => {
    setFormErrors({});

    setTimeout(() => {
      const errors = {
        level: !textParams.level,
        textTopic: !textParams.textTopic,
        grammarTopic: !allowEmptyGrammar && !textParams.grammarTopic,
        sentencesAmount: !textParams.sentencesAmount,
        level: !textParams.level,
      };
      setFormErrors(errors);

      if (Object.values(errors).some((e) => e)) return;

      generateText();
    });
  };

  useEffect(() => {
    if (exercisesStore.textMode === TextModes.Generated) return;
    if (exercisesStore.generatedText === null) {
      setTextParams(DefaultTextParams);
    }
  }, [exercisesStore.generatedText]);

  useEffect(() => {
    setFormErrors({});
  }, [textParams]);

  if (mediaType !== MediaTypes.GeneratedText) return null;

  if (modeSelect)
    return (
      <>
        <Label text={t("exercises.generating_text_info")} />
        <div className={cl.modeButtonsContainer}>
          <div className={cl.buttonContainer}>
            <Button
              style={{ gap: 12 }}
              icon={<IconStars className={cl.icon} />}
              variant={"grey"}
              text={t("exercises.generate_text")}
              onClick={() => {
                exercisesStore.setTextMode("generated");
                setModeSelect(false);
              }}
            />
          </div>
          <div className={cl.buttonContainer}>
            <Button
              style={{ gap: 12 }}
              icon={<IconText className={cl.icon} />}
              variant={"grey"}
              text={t("exercises.use_own")}
              onClick={() => {
                exercisesStore.setTextMode("custom");
                exercisesStore.setGeneratedText("");
                setModeSelect(false);
              }}
            />
          </div>
        </div>
      </>
    );

  return exercisesStore.generatedText !== null ? (
    <>
      <MyAdjustableTextarea
        placeholder={t("exercises.media_placeholder")}
        value={exercisesStore.generatedText ?? ""}
        onChange={exercisesStore.setGeneratedText}
        className={cl.text}
      />
    </>
  ) : (
    <div className={cl.generateText}>
      <div className={cl.horizontalContainer}>
        <div>
          <Label
            text={t("exercises.exercise_level")}
            isError={formErrors["level"]}
          />
          <ToggleButton
            style={{ width: 212 }}
            label={t("users.level")}
            options={LanguageLevels}
            selectedOption={textParams.level}
            variant={formErrors["level"] ? "error" : "outlined"}
            setOption={(level) => setTextParams({ ...textParams, level })}
          />
        </div>
        <div>
          <Label text={t("exercises.genre")} />
          <Select
            style={{ width: 212, marginRight: "auto" }}
            label={t("exercises.genre")}
            variant={"light"}
            value={textParams.genre}
            options={Object.values(TextGenres).map((g) => ({
              value: g,
              name: t(`exercises.${g}`),
            }))}
            onChange={(genre) => setTextParams({ ...textParams, genre })}
            height={250}
          />
        </div>
      </div>
      <div>
        <Label text={t("exercises.topic")} isError={formErrors["textTopic"]} />
        <Input
          variant={formErrors["textTopic"] ? "erroredSmall" : "outlinedSmall"}
          placeholder={t("exercises.topic_example")}
          value={textParams.textTopic}
          onChange={(textTopic) => setTextParams({ ...textParams, textTopic })}
        />
      </div>
      <div>
        <Label
          text={t("exercises.text_theme")}
          isError={formErrors["grammarTopic"]}
        />
        <Input
          variant={
            formErrors["grammarTopic"] ? "erroredSmall" : "outlinedSmall"
          }
          placeholder={t("exercises.text_theme_example")}
          value={textParams.grammarTopic}
          onChange={(grammarTopic) =>
            setTextParams({ ...textParams, grammarTopic })
          }
        />
      </div>
      <div>
        <Label text={t("exercises.vocabulary")} />
        <div className={cl.inputContainer}>
          <Input
            variant={"outlinedSmall"}
            label={t("exercises.vocabulary")}
            placeholder={"social media, communication, information..."}
            value={textParams.vocabulary}
            onChange={(vocabulary) =>
              setTextParams({ ...textParams, vocabulary })
            }
          />
          <Button
            variant={"purple"}
            text={t("exercises.use_word_pack")}
            onClick={() => setVocabularyModal(true)}
          />
        </div>
      </div>

      <p className={cl.rangeLabel}>{`${t("exercises.text_sentence_count")} (${
        textParams.sentencesAmount ?? DefaultTextParams.sentencesAmount
      })`}</p>
      <Range
        value={textParams.sentencesAmount}
        min={TextRange.Min}
        step={TextRange.Step}
        max={TextRange.Max}
        onChange={setSentencesAmount}
      />
      <div className={cl.buttonsContainer}>
        <Button
          style={{ margin: "auto" }}
          text={t("exercises.generate_text")}
          variant={"purple"}
          onClick={handleTextGenerate}
          isLoading={generateLoading}
        />
      </div>
      <SelectTargetVocabulary
        visible={vocabularyModal}
        setVisible={setVocabularyModal}
        onSelect={(vocabulary) => setTextParams({ ...textParams, vocabulary })}
      />
    </div>
  );
};

export default observer(GenerateText);
