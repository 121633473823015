import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import ProgressService from "../../../../../../api/ProgressService";
import exercisesStore from "../../../../../TeacherContent/store/exercisesStore";
import lessonsStore from "../../../../store/lessonsStore";
import { Button } from "../../../../../../teacherComponents";
import { StudentLessonStatuses } from "../../../../data/constants";
import { ReactComponent as IconPlan } from "../../../../../../assets/svg/lessons-plan.svg";
import cl from "./ModalControls.module.css";

const ModalControls = ({ lesson, isEmpty }) => {
  const { t } = useTranslation();

  const [student, setStudent] = useState();

  const { currentStudentLessons } = lessonsStore;

  const [isDone, setIsDone] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isAssigned, setIsAssigned] = useState(false);

  const handleLessonAssign = async () => {
    await ProgressService.assignLesson({
      studentId: student?.id,
      lessonId: lesson.id,
    });

    const newLessons = currentStudentLessons.some(
      (l) => l.lessonId === lesson.id
    )
      ? currentStudentLessons.map((l) =>
          l.lessonId === lesson.id
            ? { ...l, status: StudentLessonStatuses.Postponed }
            : l
        )
      : [
          ...currentStudentLessons,
          { lessonId: lesson.id, status: StudentLessonStatuses.Postponed },
        ];

    lessonsStore.setCurrentStudentLessons(newLessons);
    setIsAssigned(true);
  };

  const handleLessonShare = async () => {
    await ProgressService.assignLesson({
      studentId: student?.id,
      lessonId: lesson.id,
    });

    await ProgressService.sendLessonData({
      studentId: student?.id,
      lessonId: lesson.id,
    });

    const newLessons = currentStudentLessons.some(
      (l) => l.lessonId === lesson.id
    )
      ? currentStudentLessons.map((l) =>
          l.lessonId === lesson.id
            ? { ...l, status: StudentLessonStatuses.Unfinished }
            : l
        )
      : [
          ...currentStudentLessons,
          { lessonId: lesson.id, status: StudentLessonStatuses.Unfinished },
        ];

    lessonsStore.setCurrentStudentLessons(newLessons);
    setIsActive(true);
  };

  const handleLessonEvaluate = async () => {
    await ProgressService.evaluateLesson({
      studentId: student?.id,
      lessonId: lesson.id,
      status: StudentLessonStatuses.Done,
    });

    const newLessons = currentStudentLessons.some(
      (l) => l.lessonId === lesson.id
    )
      ? currentStudentLessons.map((l) =>
          l.lessonId === lesson.id
            ? { ...l, status: StudentLessonStatuses.Done }
            : l
        )
      : [
          ...currentStudentLessons,
          { lessonId: lesson.id, status: StudentLessonStatuses.Done },
        ];
    lessonsStore.setCurrentStudentLessons(newLessons);
    setIsDone(true);
  };

  useEffect(() => {
    const currentLesson = currentStudentLessons.find(
      (l) => l.lessonId === lesson.id
    );
    setIsDone(currentLesson?.status === StudentLessonStatuses.Done);
    setIsActive(currentLesson?.status === StudentLessonStatuses.Unfinished);
    setIsAssigned(currentLesson?.status === StudentLessonStatuses.Postponed);
  }, [currentStudentLessons]);

  useEffect(() => {
    setStudent(exercisesStore.currentStudent);
  }, [exercisesStore.currentStudent]);

  return (
    <div className={cl.container}>
      {student && isEmpty ? (
        <p className={cl.emptyAlert}>{t("exercises.empty_lesson")}</p>
      ) : isDone ? (
        <>{t("exercises.lesson_done")}</>
      ) : isActive ? (
        <Button
          variant={"purple"}
          text={t("exercises.mark_as_done")}
          onClick={handleLessonEvaluate}
        />
      ) : (
        <>
          <Button
            style={{ gap: 12, flexGrow: 1 }}
            isDisabled={isAssigned}
            icon={
              <IconPlan
              className={cl.planIcon}
              />
            }
            variant={"purple"}
            onClick={handleLessonAssign}
            text={t(
              isAssigned ? "exercises.unplan_lesson" : "exercises.plan_lesson"
            )}
          />
          <Button
            style={{ flexGrow: 1 }}
            text={t("exercises.assign_lesson")}
            variant={"purple"}
            onClick={handleLessonShare}
          />
        </>
      )}
    </div>
  );
};

export default observer(ModalControls);
