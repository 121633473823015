import { makeAutoObservable } from "mobx";
import { ExercisesPaginationCount, DefaultView } from "../data/constants";
import { Chapters } from "../../TeacherLessons/data/constants";

class ExercisesStore {
  view = DefaultView;

  exercises = [];
  currentExercise = {};
  currentTrack = null;

  page = 0;
  type = "";
  difficulty = localStorage.getItem("contentDefaultDifficulty")
    ? JSON.parse(localStorage.getItem("contentDefaultDifficulty"))
    : "";
  age = localStorage.getItem("contentDefaultAge")
    ? JSON.parse(localStorage.getItem("contentDefaultAge"))
    : "";

  exerciseSearch = "";
  dictionarySearch = "";
  situationSearch = "";
  trackSearch = "";

  editMode = false;
  showAnswers = false;
  paginationEnd = false;

  trackText = null;
  generatedText = null;
  textMode = null;

  section = null;
  currentStudent = null;
  currentStudentChapters = {};

  unassigned = false;

  chaptersModalType = "";
  studentSearch = "";
  categoryOptions = [];
  trackCategory = "";
  situationCategoryOptions = [];
  situationCategory = "";

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true, deep: true });
  }

  setView(view) {
    this.view = view;
  }

  setPage(page) {
    this.page = page;
  }

  setExercises(exercises) {
    this.exercises = exercises;
  }

  setCurrentExercise(currentExercise) {
    this.currentExercise = currentExercise;
  }

  setCurrentTrack(currentTrack) {
    this.currentTrack = currentTrack;
  }

  setSearchString(searchString) {
    switch (this.view) {
      case Chapters.Track: {
        this.trackSearch = searchString;
        break;
      }
      case Chapters.Situation: {
        this.situationSearch = searchString;
        break;
      }
      case Chapters.Exercise: {
        this.exerciseSearch = searchString;
        break;
      }
      case Chapters.Dictionary: {
        this.dictionarySearch = searchString;
        break;
      }
      default:
        return;
    }
  }

  setType(type) {
    this.type = type;
  }

  setDifficulty(difficulty) {
    this.difficulty = difficulty;
    localStorage.setItem(
      "contentDefaultDifficulty",
      JSON.stringify(difficulty)
    );
  }

  setAge(age) {
    this.age = age;
    localStorage.setItem("exercisesDefaultAge", JSON.stringify(age));
  }

  setPaginationEnd(paginationEnd) {
    this.paginationEnd = paginationEnd;
  }

  setEditMode(editMode) {
    this.editMode = editMode;
  }

  setShowAnswers(showAnswers) {
    this.showAnswers = showAnswers;
  }

  setCurrentStudent(currentStudent) {
    this.currentStudent = currentStudent;
  }

  setCurrentStudentChapters(currentStudentChapters) {
    this.currentStudentChapters = currentStudentChapters;
  }

  setUnassigned(unassigned) {
    this.unassigned = unassigned;
  }

  setSection(section) {
    this.section = section;
  }

  setExercisesData(data) {
    const { items } = data;
    if (!items.length || items.length < ExercisesPaginationCount) {
      this.setPaginationEnd(true);
    }
    const exercises = (
      this.page > 0 ? [...this.exercises, ...items] : items
    ).filter(
      (t, index, self) => !self.slice(0, index).some((item) => item.id === t.id)
    );
    this.setExercises(exercises);
  }

  setGeneratedText(generatedText) {
    this.generatedText = generatedText;
  }

  setTextMode(textMode) {
    this.textMode = textMode;
  }

  setTrackText(trackText) {
    this.trackText = trackText;
  }

  resetSearch() {
    this.setPage(0);
    this.setPaginationEnd(false);
    this.setExercises([]);
    this.setCurrentExercise({});
  }

  resetFilter() {
    this.setDifficulty("");
    this.setSearchString("");
    this.setType("");
  }

  updateCurrentExercise({ title, description, difficulty }) {
    if (!this.currentExercise || !this.currentExercise.id) return;
    if (title !== undefined)
      this.setCurrentExercise({ ...this.currentExercise, title });
    if (description !== undefined)
      this.setCurrentExercise({ ...this.currentExercise, description });
    if (difficulty !== undefined)
      this.setCurrentExercise({ ...this.currentExercise, difficulty });
  }

  updateExercise(exerciseObj) {
    this.setCurrentExercise({});
    this.setExercises(
      this.exercises.map((e) => (e.id === exerciseObj.id ? exerciseObj : e))
    );
    this.setEditMode(false);
    setTimeout(() => {
      this.setCurrentExercise({ ...exerciseObj });
    }, 100);
  }

  deleteExercise(id) {
    const deletedIndex = this.exercises.findIndex((e) => e.id === id);
    const updatedExercises = this.exercises.filter((e) => e.id !== id);

    this.setExercises(updatedExercises);

    if (this.currentExercise && this.currentExercise.id === id) {
      const nextExercise =
        updatedExercises[deletedIndex] ||
        updatedExercises[deletedIndex - 1] ||
        {};
      this.setCurrentExercise(nextExercise);
      this.setEditMode(false);
    }
  }

  resetTrackParams() {
    this.setCurrentTrack();
    this.setTrackText(null);
    this.setGeneratedText(null);
  }

  setChaptersModalType(chaptersModalType) {
    this.chaptersModalType = chaptersModalType;
  }

  setStudentSearch(studentSearch) {
    this.studentSearch = studentSearch;
  }

  setCategoryOptions(categoryOptions) {
    this.categoryOptions = categoryOptions;
  }

  setTrackCategory(trackCategory) {
    this.trackCategory = trackCategory;
  }

  setSituationCategoryOptions(situationCategoryOptions) {
    this.situationCategoryOptions = situationCategoryOptions;
  }

  setSituaitonCategory(situationCategory) {
    this.situationCategory = situationCategory;
  }
}

const exercisesStore = new ExercisesStore();
export default exercisesStore;
