import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../../../hooks/useFetching";
import lessonsStore from "../../../TeacherLessons/store/lessonsStore";
import TrackService from "../../../../api/TrackService";
import ProgressService from "../../../../api/ProgressService";
import { UserChapterSection } from "..";
import { Chapters } from "../../../TeacherLessons/data/constants";
import { Situation } from "../../../TeacherContent/views/SituationsView/components";
import { ReactComponent as IconEyes } from "../../../../assets/svg/lessons-eyes.svg";
import { Player } from "../../..";
import SituationsService from "../../../../api/SituationsService";
import { Button } from "../../../../teacherComponents";
import cl from "./UserProgress.module.css";
import { Loader } from "../../../../UI";
import { LanguageLevels } from "../../../../data/common";

const UserProgress = ({ user, lang, onClose, userLoading }) => {
  const { t } = useTranslation();

  const { id, email, level, intensity } = user;

  const [tracks, setTracks] = useState([]);
  const [situations, setSituations] = useState([]);
  const [words, setWords] = useState([]);

  const { currentChapter } = lessonsStore;

  const [getTracks, tracksLoading] = useFetching(async () => {
    if (!id) return;

    const { data } = await TrackService.getHistory({
      offset: 0,
      limit: 500,
      userId: id,
    });
    setTracks(
      data.items.map((t) => ({
        ...t,
        progress: t?.duration ? t?.trackTime / t.duration : 0,
      }))
    );
  });

  const [getSituations, situationsLoading] = useFetching(async () => {
    if (!id) return;

    const { data } = await ProgressService.getStudentSituationsOld({
      userId: id,
      lang,
    });
    const ids = data.map((s) => s.situation_id);

    const { data: categories } = await SituationsService.getAvailableCategories(
      {
        language: lang.toUpperCase(),
      }
    );

    const situations = categories
      .map((c) => c.situations)
      .flat()
      .filter((s) => ids.includes(s.id));

    const dataMap = new Map(data.map((d) => [d.situation_id, d]));

    const mergedSituations = situations.map((s) => ({
      ...s,
      ...(dataMap.get(s.id)?.data || {}),
    }));
    setSituations(mergedSituations);
  });

  const [getWords, wordsLoading] = useFetching(async () => {
    if (!id) return;

    const { data } = await ProgressService.getStudentWordsOld({
      lang,
      userId: id,
    });
    if (data?.length) {
      setWords(
        data
          .map((w) => ({ word: w?.data?.word || "", status: w.status }))
          .sort((a, b) => {
            if (a.status === "active" && b.status !== "active") {
              return 1;
            }
            if (a.status !== "active" && b.status === "active") {
              return -1;
            }
            return 0;
          })
      );
    } else {
      setWords([]);
    }
  });

  const renderCurrentChapter = () => {
    if (currentChapter.podcastImage)
      return (
        <Player
          key={"modalView"}
          track={{ id: currentChapter?.id, title: currentChapter?.title }}
        />
      );
    if (currentChapter.info)
      return <Situation situation={currentChapter} activeLang={lang} />;
    return (
      <div className={cl.emptyAlert}>
        <IconEyes />
        <p className={cl.alertTitle}>
          {t(`Select video or situation to preview`)}
        </p>
      </div>
    );
  };

  const isLoading =
    situationsLoading || tracksLoading || wordsLoading || userLoading;

  useEffect(() => {
    if (!lang) return;
    getTracks();
    getSituations();
    getWords();
  }, [lang]);

  useEffect(
    () => () => {
      lessonsStore.setCurrentChapter({});
    },
    []
  );

  return (
    <>
      <div className={cl.toolbar}>
        <p className={cl.label}>
          {`Email:`}
          <span>{email ?? "unknown"}</span>
        </p>
        <p className={cl.label}>
          {`Language:`}
          <span>{lang.toUpperCase()}</span>
        </p>
        <p className={cl.label}>
          {`Level:`}
          <span>{LanguageLevels[level]}</span>
        </p>
        <p className={cl.label}>
          {`Intensity:`}
          <span>{intensity}</span>
        </p>
        <Button
          style={{ marginLeft: "auto" }}
          variant={"red"}
          text={t("buttons.close")}
          onClick={onClose}
        />
      </div>
      <div className={cl.container}>
        <div className={cl.aside}>
          {isLoading ? (
            <Loader style={{ height: "100%" }} />
          ) : (
            ![...tracks, ...situations, ...words].length && (
              <div className={cl.emptyProgress}>
                <p className={cl.emptyProgressTitle}>
                  This user has no progress
                </p>
                <Button
                  variant={"red"}
                  text={t("buttons.close")}
                  onClick={onClose}
                />
              </div>
            )
          )}
          <UserChapterSection type={Chapters.Track} chapters={tracks} />
          <UserChapterSection type={Chapters.Situation} chapters={situations} />
          <UserChapterSection type={"words_list"} chapters={words} />
        </div>
        <div className={cl.preview}>{renderCurrentChapter()}</div>
      </div>
    </>
  );
};

export default observer(UserProgress);
