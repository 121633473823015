import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import exercisesStore from "../../store/exercisesStore";
import { Button } from "../../../../teacherComponents";
import { ReactComponent as IconAdd } from "../../../../assets/svg/lessons-add.svg";
import { ReactComponent as IconCloud } from "../../../../assets/svg/lessons-cloud.svg";
import { ReactComponent as IconStars } from "../../../../assets/svg/lessons-stars.svg";
import {
  Chapters,
  ChapterModals,
} from "../../../TeacherLessons/data/constants";

const GenerateChaptersButtons = ({ view, variant }) => {
  const { t } = useTranslation();

  const renderCreateIcon = () => {
    switch (view) {
      case Chapters.Track:
        return <IconCloud />;
      case Chapters.Dictionary:
        return <IconStars />;
      case Chapters.Situation:
      case Chapters.Exercise:
        return <IconStars />;
      default:
        return "";
    }
  };

  return (
    <>
      <Button
        variant={variant}
        text={t(`exercises.add_new_${view}`)}
        icon={renderCreateIcon()}
        onClick={() => exercisesStore.setChaptersModalType(ChapterModals[view])}
      />
      <Button
        variant={variant}
        icon={<IconAdd />}
        text={t(`exercises.add_fav_${view}`)}
        onClick={() => {
          exercisesStore.setChaptersModalType(view);
        }}
      />
    </>
  );
};

export default observer(GenerateChaptersButtons);
