import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Chapters } from "../../../../data/constants";
import cl from "./FileCard.module.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const FileCard = ({ file, isActive, setActive }) => {
  const { t } = useTranslation();
  const [imageError, setImageError] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const isPdf = file.endsWith(".pdf");

  const handleError = () => {
    setImageError(true);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleFileClick = () => {
    const fObj = { src: file, isPdf, type: Chapters.File };
    setActive(fObj);
  };

  return (
    <div
      className={`${cl.file} ${isActive ? cl.active : ""}`}
      onClick={handleFileClick}
    >
      <div className={`${cl.imageContainer} `}>
        {imageError || isPdf ? (
          isPdf ? (
            <div className={cl.pdfPreview}>
              <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                className={cl.pdfDocument}
              >
                <Page className={cl.pdfDocument} pageNumber={1} />
              </Document>
              {numPages && (
                <p>{t("exercises.pdf_pages", { count: numPages })}</p>
              )}
            </div>
          ) : (
            <p className={cl.label}>{t("exercises.no_file_preview")}</p>
          )
        ) : (
          <img src={file} alt={file} onError={handleError} />
        )}
      </div>
      <div className={cl.cardInner}>
        <p className={cl.title}>
          {t(isPdf ? "exercises.pdf_file" : "exercises.picture_file")}
        </p>
      </div>
    </div>
  );
};

export default FileCard;
