import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../../../../../hooks/useFetching";
import ExerciseService from "../../../../../../api/ExerciseService";
import ProgressService from "../../../../../../api/ProgressService";
import TrackService from "../../../../../../api/TrackService";
import exercisesStore from "../../../../store/exercisesStore";
import studentsStore from "../../../../../TeacherStudents/store/studentsStore";
import lessonsStore from "../../../../../TeacherLessons/store/lessonsStore";
import teachersStore from "../../../../../Teachers/store/teachersStore";
import exercisesPlayer from "../../../../store/exercisesPlayer";
import { Loader } from "../../../../../../UI";
import {
  AdvancedQuizPreview,
  ClozePreview,
  SubstitutionPreview,
  MultichoicePreview,
  QuizPreview,
  SentenceOrderPreview,
  SummaryPreview,
  WordsOrderPreview,
  FreeFormQuestionsPreview,
} from "./components";
import {
  GrammarExerciseTypes,
  MediaExerciseTypes,
} from "../../../../data/constants";
import { LanguageLevels } from "../../../../../../data/common";
import { ExercisePlayer, ExercisePlayerControls } from "..";
import { Button, Input } from "../../../../../../teacherComponents";
import { Chapters, StudentExerciseStatuses, Views } from "../../../../../TeacherLessons/data/constants";
import cl from "./Preview.module.css";

const TICKS_IN_SECOND = 10000000;

const Preview = ({ exerciseId, hideEdit }) => {
  const { t } = useTranslation();

  const { currentTeacher } = teachersStore;
  const { currentLesson } = lessonsStore;
  const {
    currentStudent,
    currentStudentChapters,
    chaptersModalType,
    showAnswers,
    view,
  } = exercisesStore;

  const containerRef = useRef();

  const isTeacher = view === Views.Teachers && currentTeacher;

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };
  const isExerciseHaveAnswers = () => {
    return ![
      MediaExerciseTypes.FreeFormQuestions,
      GrammarExerciseTypes.StatementsTransformation,
      MediaExerciseTypes.Presentation,
    ].includes(exerciseObj.type);
  };

  const isRenderEditButton = () => {
    if (hideEdit) return false;
    if (chaptersModalType === Chapters.Exercise) return false;
    if (isTeacher) return false;
    if (currentStudent && currentLesson) return false;
    return true;
  };

  const [exerciseObj, setExerciseObj] = useState({});
  const [results, setResults] = useState();
  const [status, setStatus] = useState("");
  const [comment, setComment] = useState("");

  const [getExerciseData, dataLoading] = useFetching(async () => {
    if (!exerciseId) return;
    const { data } = await ExerciseService.getExercise({
      id: exerciseId,
    });
    if (data.trackId && data.trackInterval) {
      await getTrack({
        trackId: data.trackId,
        trackInterval: data.trackInterval,
      });
    } else {
      exercisesPlayer.resetMediaParams();
    }
    setExerciseObj(data);
  });

  const [getTrack, trackLoading] = useFetching(
    async ({ trackId, trackInterval }) => {
      const { data } = await TrackService.getTrack(trackId);
      const rangeInTicks = trackInterval.map((t) => t * TICKS_IN_SECOND);
      exercisesPlayer.setTrackData(data);
      exercisesPlayer.setMediaRange(rangeInTicks);
      exercisesPlayer.setCurrentTime(rangeInTicks[0]);
    }
  );

  const [evaluateExercise] = useFetching(async () => {
    await ProgressService.evaluateExercise({
      studentId: currentStudent.id,
      exerciseId,
      status: StudentExerciseStatuses.done,
      comment,
    });
    setStatus(StudentExerciseStatuses.done);
    const { data } = await ProgressService.getNotifications();
    studentsStore.setNotifications(data.items ?? []);
  });

  const getMediaPreview = () => {
    if (exerciseObj.trackId && exerciseObj.trackInterval) {
      return (
        <div className={cl.videoContainer} key={exerciseObj.trackId}>
          <ExercisePlayer isPreview={true} />
          <ExercisePlayerControls mode={"video"} isPreview={true} />
          <p className={cl.textAlert}>
            {t("exercises.exercise_preview_text_info")}
          </p>
          <div className={cl.exerciseText}>{exerciseObj.text}</div>
        </div>
      );
    }
    if (exerciseObj.text) {
      return <div className={cl.exerciseText}>{exerciseObj.text}</div>;
    }

    return <></>;
  };

  const getPreview = () => {
    switch (exerciseObj.type) {
      case GrammarExerciseTypes.Multichoice:
        return (
          <MultichoicePreview
            exerciseObj={exerciseObj}
            results={results}
            showAnswers={showAnswers}
          />
        );
      case GrammarExerciseTypes.Substitution:
        return (
          <SubstitutionPreview
            exerciseObj={exerciseObj}
            results={results}
            showAnswers={showAnswers}
          />
        );
      case GrammarExerciseTypes.Cloze:
        return (
          <ClozePreview
            exerciseObj={exerciseObj}
            results={results}
            showAnswers={showAnswers}
          />
        );
      case MediaExerciseTypes.Quiz:
        return (
          <QuizPreview
            exerciseObj={exerciseObj}
            results={results}
            showAnswers={showAnswers}
          />
        );
      case MediaExerciseTypes.SentencesOrder:
        return (
          <SentenceOrderPreview
            exerciseObj={exerciseObj}
            showAnswers={showAnswers}
            results={results}
            setShowAnswers={exercisesStore.setShowAnswers}
          />
        );
      case GrammarExerciseTypes.WordsOrder:
        return (
          <WordsOrderPreview
            exerciseObj={exerciseObj}
            results={results}
            showAnswers={showAnswers}
          />
        );
      case MediaExerciseTypes.Summary:
        return (
          <SummaryPreview
            exerciseObj={exerciseObj}
            results={results}
            showAnswers={showAnswers}
            setShowAnswers={exercisesStore.setShowAnswers}
          />
        );
      case MediaExerciseTypes.AdvancedQuiz:
        return (
          <AdvancedQuizPreview
            exerciseObj={exerciseObj}
            results={results}
            showAnswers={showAnswers}
          />
        );
      case MediaExerciseTypes.Presentation:
        return (
          <FreeFormQuestionsPreview
            exerciseObj={exerciseObj}
            results={results}
            showAnswers={showAnswers}
          />
        );
      case MediaExerciseTypes.FreeFormQuestions:
        return (
          <FreeFormQuestionsPreview
            exerciseObj={exerciseObj}
            results={results}
            showAnswers={showAnswers}
          />
        );
      case GrammarExerciseTypes.StatementsTransformation:
        return (
          <FreeFormQuestionsPreview
            exerciseObj={exerciseObj}
            results={results}
            showAnswers={showAnswers}
          />
        );
      default:
        return <></>;
    }
  };

  const handleShowAnswers = () => {
    exercisesStore.setShowAnswers(!showAnswers);
  };

  const renderResultsControl = () => {
    if (!results) return null;

    if (status === StudentExerciseStatuses.awaitingEvaluation) {
      return (
        <>
          <Input
            placeholder={t("buttons.comment")}
            variant={"filled"}
            style={{ marginBottom: 12 }}
            value={comment}
            onChange={setComment}
          />
          <Button
            text={t("exercises.evaluate")}
            variant={"red"}
            style={{ margin: "0 auto" }}
            onClick={evaluateExercise}
          />
        </>
      );
    }

    const text =
      status === StudentExerciseStatuses.unfinished
        ? "exercises.exercise_unfinished"
        : "exercises.exercise_done";

    return <p className={cl.doneText}>{t(text)}</p>;
  };

  const renderButtons = () => {
    if ((!isRenderEditButton() && !isExerciseHaveAnswers()) || results) {
      return null;
    }

    return (
      <div className={cl.buttons}>
        {isRenderEditButton() && (
          <Button
            text={t("buttons.edit")}
            variant={"red"}
            onClick={() => exercisesStore.setEditMode(true)}
          />
        )}
        {isExerciseHaveAnswers() && (
          <Button
            text={t(showAnswers ? "buttons.reset" : "exercises.show_answers")}
            variant={showAnswers ? "dark" : "grey"}
            onClick={handleShowAnswers}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    setComment("");
    setExerciseObj({});
    if (!exerciseId) return;
    exercisesStore.setShowAnswers(false);
    setTimeout(() => {
      getExerciseData();
    }, 100);
  }, [exerciseId]);

  useEffect(() => {
    const currentStudentProgress = currentStudentChapters.exercises?.find(
      (e) => e.id === exerciseId
    );
    setResults(currentStudentProgress?.data ?? null);
    setStatus(currentStudentProgress?.status ?? "");
  }, [exerciseId, currentStudentChapters]);

  useEffect(() => {
    if (showAnswers) {
      scrollToBottom();
    }
  }, [showAnswers]);

  if (dataLoading || trackLoading) return <Loader style={{ margin: "auto" }} />;

  return (
    <div className={cl.preview}>
      <div className={cl.container} ref={containerRef}>
        <p className={cl.exerciseTitle}>
          {exerciseObj.title}
          <span>{LanguageLevels[exerciseObj.difficulty]}</span>
        </p>
        <p className={cl.exerciseDescription}>{exerciseObj.description}</p>

        <div className={cl.previewContainer}>
          {getMediaPreview()}
          {getPreview()}
        </div>
      </div>
      <div className={cl.buttonsContainer}>
        {renderResultsControl()}
        {renderButtons()}
      </div>
    </div>
  );
};

export default observer(Preview);
