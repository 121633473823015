import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import exercisesStore from "../../../../store/exercisesStore";
import { useFetching } from "../../../../../../hooks/useFetching";
import ExerciseService from "../../../../../../api/ExerciseService";
import {
  Button,
  Input,
  Label,
  Range,
  ToggleButton,
} from "../../../../../../teacherComponents";
import GenerateText from "../GenerateText/GenerateText";
import {
  GrammarExerciseTypes,
  MediaExerciseTypes,
  MediaTypes,
  QuestionsRange,
  TextModes,
} from "../../../../data/constants";
import { LanguageLevels } from "../../../../../../data/common";
import cl from "./TextExerciseOptions.module.css";

const TextExerciseOptions = ({ options, setOptions, onCreate }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const { generatedText } = exercisesStore;

  const [errorMessage, setErrorMessage] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const { topic, type, difficulty, sentencesAmount } = options;

  const setTopic = (topic) => setOptions({ ...options, topic });
  const setDifficulty = (difficulty) => setOptions({ ...options, difficulty });

  const setSentencesAmount = (e) =>
    setOptions({ ...options, sentencesAmount: e.target.value });

  const [getChatResponse, isChatLoading, chatError, resetError] = useFetching(
    async () => {
      const { data } = await ExerciseService.createExercise({
        ...options,
        difficulty: LanguageLevels.indexOf(difficulty),
        text: exercisesStore.generatedText,
        lang,
      });
      if (data) {
        onCreate(data);
      }
    }
  );

  const isRange = () =>
    generatedText !== null &&
    [GrammarExerciseTypes.StatementsTransformation].includes(options.type);

  const isGap = () =>
    generatedText !== null &&
    [
      GrammarExerciseTypes.Cloze,
      GrammarExerciseTypes.Multichoice,
      GrammarExerciseTypes.Substitution,
    ].includes(options.type);

  const handleExerciseGenerate = () => {
    setFormErrors({});
    setTimeout(() => {
      const errors = {
        topic: isGap() && !topic,
        text: !generatedText?.length,
        level: !options.difficulty,
      };
      setFormErrors(errors);

      if (Object.values(errors).some((e) => e)) return;
      getChatResponse();
    });
  };

  useEffect(() => {
    setFormErrors({});
  }, [options]);

  useEffect(() => {
    setErrorMessage(chatError?.message);
  }, [chatError]);

  return (
    <div className={cl.exerciseOptions}>
      <div
        className={cl.inputsContainer}
        style={{ justifyContent: "space-between" }}
      >
        {generatedText !== null && (
          <>
            <div>
              <Label
                text={t("exercises.exercise_level")}
                isError={formErrors["level"]}
              />
              <ToggleButton
                style={{ width: 212 }}
                label={t("users.level")}
                options={LanguageLevels}
                selectedOption={options.difficulty}
                variant={formErrors["level"] ? "error" : "outlined"}
                setOption={setDifficulty}
              />
            </div>
            <Label
              style={{ marginBottom: -12 }}
              isError={formErrors["text"]}
              text={t("exercises.exercise_text")}
            />
          </>
        )}
        <GenerateText mediaType={MediaTypes.GeneratedText} />
      </div>

      {isRange() && (
        <div className={cl.inputContainer}>
          <Label
            text={`${t(
              type === MediaExerciseTypes.SentencesOrder
                ? "exercises.sentence_count"
                : "exercises.question_count"
            )} (${sentencesAmount ?? QuestionsRange.Default})`}
          />
          <Range
            style={{ marginTop: "auto" }}
            value={sentencesAmount ?? QuestionsRange.Default}
            min={QuestionsRange.Min}
            step={QuestionsRange.Step}
            max={QuestionsRange.Max}
            onChange={setSentencesAmount}
          />
        </div>
      )}

      {isGap() && (
        <div>
          <Label text={t("exercises.theme")} isError={formErrors["topic"]} />
          <Input
            placeholder={t("exercises.grammar_example")}
            variant={formErrors[topic] ? "erroredSmall" : "outlinedSmall"}
            value={topic}
            onChange={setTopic}
          />
        </div>
      )}

      {errorMessage ? (
        <div className={cl.optionsContainer}>
          <p className={cl.errorMessage}>{`${t(
            "dictionary_administration.generation_error"
          )} (${errorMessage})`}</p>
          <Button
            text={t("buttons.reset")}
            variant={"red"}
            onClick={resetError}
          />
        </div>
      ) : (
        generatedText !== null && (
          <div
            className={cl.optionsContainer}
            style={{ justifyContent: "space-between" }}
          >
            <Button
              text={t("exercises.generate_exercise")}
              variant={"purple"}
              onClick={handleExerciseGenerate}
              isLoading={isChatLoading}
            />
            {exercisesStore.textMode === TextModes.Generated && (
              <Button
                text={t("buttons.back")}
                variant={"grey"}
                onClick={() => {
                  exercisesStore.setGeneratedText(null);
                }}
              />
            )}
          </div>
        )
      )}
    </div>
  );
};

export default observer(TextExerciseOptions);
