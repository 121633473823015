export const Chapters = {
  Track: "track",
  Situation: "situation",
  Exercise: "exercise",
  Dictionary: "words",
  File: "file",
};

export const Views = {
  Lessons: "lessons",
  Students: "students",
  Teachers: "teachers",
  Tutorial: "tutorial",
};

export const ChapterModals = {
  [Chapters.Track]: "track_modal",
  [Chapters.Situation]: "situation_modal",
  [Chapters.Exercise]: "exercise_modal",
  [Chapters.Dictionary]: "words_modal",
};

export const StudentLessonStatuses = {
  Unfinished: "unfinished",
  Postponed: "postponed",
  Done: "done",
};

export const StudentExerciseStatuses = {
  unfinished: "unfinished",
  awaitingEvaluation: "awaitingEvaluation",
  done: "done",
};
