import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetching } from "../../../../../../hooks/useFetching";
import ProgressService from "../../../../../../api/ProgressService";
import { ToggleButton } from "../../../../../../teacherComponents";
import {
  Input,
  MicroButton,
  Tooltip,
} from "../../../../../../teacherComponents";
import { ReactComponent as IconBin } from "../../../../../../assets/svg/lessons-bin.svg";
import WordCard from "../WordCard/WordCard";
import { LanguageLevels } from "../../../../../../data/common";
import cl from "./CategoryWords.module.css";

const CategoryWords = ({
  dictionary,
  category,
  currentWord,
  setCurrentWord,
  addedWord,
  setAddedWord,
  pendingWords = [],
  onAdd,
  onDelete,
  onUpdate,
  isInvalid,
  setIsInvalid,
}) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const { words } = category;

  const [difficulty, setDifficulty] = useState("");
  const [fetchedWords, setFetchedWords] = useState([]);
  const [inputTimeout, setInputTimeout] = useState();
  const [spellcheckError, setSpellcheckError] = useState(false);

  const [getCategoryWords] = useFetching(async () => {
    const { data } = await ProgressService.getCategoryWords({
      categoryId: category.id,
      lang,
    });
    setFetchedWords(data.items);
    setCurrentWord(data.items[0]);
  });

  const [updateCategory] = useFetching(async ({ level }) => {
    await ProgressService.updateWordsCategory({
      id: category.id,
      level,
    });
  });

  const handleDifficultyChange = (newDifficulty) => {
    const newLevel = LanguageLevels.indexOf(newDifficulty);
    if (category.level === newLevel) return;

    updateCategory({ level: newLevel });
    onUpdate({ ...category, level: newLevel });
  };

  const handleWordInput = (text) => {
    setSpellcheckError(false);
    if (inputTimeout) {
      clearTimeout(inputTimeout);
    }

    const timeoutId = setTimeout(() => {
      setSpellcheckError(!dictionary.check(text));
    }, 500);

    setInputTimeout(timeoutId);
    setAddedWord(text);
  };

  useEffect(() => {
    const difficulty =
      category.level !== undefined ? LanguageLevels[category.level] : "";
    setDifficulty(difficulty);
    if (!category.words) {
      getCategoryWords();
    }
  }, [category]);

  return (
    <div className={cl.category}>
      <div className={cl.titleContainer}>
        <p className={cl.title}>{category.title}</p>
        {setAddedWord && (
          <ToggleButton
            style={{ width: 212 }}
            label={t("users.level")}
            options={LanguageLevels}
            selectedOption={difficulty}
            variant={"filled"}
            setOption={handleDifficultyChange}
          />
        )}
      </div>
      <div className={cl.line} />
      {setAddedWord && (
        <div className={cl.inputContainer}>
          {addedWord && spellcheckError && (
            <Tooltip
              text={t("exercises.check_spell")}
              variant={"warning"}
              style={{ top: -48, left: 25 }}
            />
          )}
          <p>{"+"}</p>
          <Input
            onBlur={() =>
              onAdd({ word: addedWord.trim(), categoryId: category.id }, true)
            }
            placeholder={t("exercises.add_word_to_category")}
            value={addedWord}
            onChange={handleWordInput}
            onFocus={() => setCurrentWord()}
            error={isInvalid ? t("exercises.invalid_word") : ""}
            resetError={setIsInvalid}
          />
        </div>
      )}

      <div className={cl.wordsList}>
        {(words ?? fetchedWords).map((w, i) => (
          <div
            className={`${cl.word} ${
              currentWord?.word === w.word ? cl.active : ""
            }`}
            key={w.word}
            onClick={() => setCurrentWord(w)}
          >
            <span>{`${i + 1}.`}</span>
            <p>{w.word}</p>
            {setAddedWord && (
              <div className={cl.controls}>
                <MicroButton
                  icon={<IconBin style={{ filter: "grayscale(1)" }} />}
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete();
                  }}
                  size={"small"}
                  variant={"grey"}
                />
              </div>
            )}
          </div>
        ))}
        {setAddedWord &&
          pendingWords.map((w, i) => (
            <div
              className={`${cl.word} ${w.invalid ? cl.invalid : cl.pending}`}
              key={`${w.word}_${i}`}
            >
              <span>{`${words.length + i + 1}.`}</span>
              <p>{w.word}</p>
              <div className={cl.controls}>
                <MicroButton
                  icon={<IconBin style={{ filter: "grayscale(1)" }} />}
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete(w);
                  }}
                  size={"small"}
                  variant={"grey"}
                />
              </div>
            </div>
          ))}
      </div>

      <WordCard
        currentWord={currentWord}
        category={category}
        onClose={() => setCurrentWord()}
        lang={lang}
      />
    </div>
  );
};

export default CategoryWords;
