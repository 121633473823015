import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import teachersStore from "../../store/teachersStore.js";
import {
  ControlButton,
  Input,
  Separator,
  Toolbar,
} from "../../../../teacherComponents/index.js";

const TeachersSearchBar = () => {
  const { t } = useTranslation();

  const { difficulty, searchString } = teachersStore;

  return (
    <Toolbar>
      <Input
        search
        placeholder={t("library.search")}
        value={searchString}
        onChange={teachersStore.setSearchString}
      />

      <Separator />
      <ControlButton type="help" style={{ cursor: "not-allowed" }} />
    </Toolbar>
  );
};

export default observer(TeachersSearchBar);
