import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import studentsStore from "../../store/studentsStore";
import { Views } from "../../data";
import { Input } from "../../../../teacherComponents";
import { ReactComponent as IconSearch } from "../../../../assets/svg/lessons-search.svg";
import { ReactComponent as IconCollapse } from "../../../../assets/svg/lessons-collapse.svg";
import { ReactComponent as IconHelp } from "../../../../assets/svg/lessons-help.svg";

import cl from "./StudentContentNavigation.module.css";

const StudentContentNavigation = ({ view, setView }) => {
  const { t } = useTranslation();

  const { collapseCards, searchString } = studentsStore;

  const [isSearch, setIsSearch] = useState(false);

  return (
    <div className={cl.navigation}>
      {Object.values(Views).map((v) => (
        <div
          className={`${cl.navButton} ${view === v ? cl.active : ""}`}
          key={v}
          onClick={() => {
            setView(v);
          }}
        >
          {t(`exercises.${v}`)}
        </div>
      ))}
      <div className={cl.controls}>
        {isSearch ? (
          <Input
            search
            autoFocus
            variant={"filled"}
            placeholder={t("library.search")}
            value={searchString}
            onChange={studentsStore.setSearchString}
            onBlur={() => {
              setIsSearch(false);
            }}
          />
        ) : (
          <div
            className={`${cl.control} ${searchString ? cl.active : ""}`}
            onClick={() => setIsSearch(true)}
          >
            <IconSearch />
          </div>
        )}
        <div
          className={`${cl.control} ${collapseCards ? cl.active : ""}`}
          onClick={studentsStore.toggleCollapseCards}
        >
          <IconCollapse />
        </div>
        <div className={cl.control}>
          <IconHelp />
        </div>
      </div>
    </div>
  );
};

export default observer(StudentContentNavigation);
