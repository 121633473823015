import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useFetching } from "../../../../hooks/useFetching.js";
import CategoryService from "../../../../api/CategoryService.js";
import exercisesStore from "../../store/exercisesStore.js";
import {
  Checkbox,
  ControlButton,
  Input,
  Select,
  Separator,
  ToggleButton,
  Toolbar,
} from "../../../../teacherComponents/index.js";
import { LanguageLevels } from "../../../../data/common.js";
import {
  GrammarExerciseTypes,
  MediaExerciseTypes,
} from "../../data/constants.js";
import { Chapters } from "../../../TeacherLessons/data/constants.js";
import SituationsService from "../../../../api/SituationsService.js";

const ContentSearchBar = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const {
    unassigned,
    difficulty,
    type,
    view,
    categoryOptions,
    situationCategoryOptions,
  } = exercisesStore;

  const exercisesTypeOptions = [
    ...Object.values(GrammarExerciseTypes),
    ...Object.values(MediaExerciseTypes),
  ].map((type) => ({ value: type, name: t(`exercises.${type}`) }));

  const [getTrackCategories] = useFetching(async () => {
    const { data } = await CategoryService.getCategories({
      lang,
    });
    exercisesStore.setCategoryOptions(
      data.map((c) => ({ value: c.id, name: c.translation || c.title }))
    );
  });

  const [getSituationCategories] = useFetching(async () => {
    const { data } = await SituationsService.getAvailableCategories({
      language: lang.toUpperCase(),
    });
    exercisesStore.setSituationCategoryOptions(
      data.map((c) => ({ value: c.id, name: c.translation || c.title }))
    );
  });

  const getSearchString = () => {
    switch (view) {
      case Chapters.Track: {
        return exercisesStore.trackSearch;
      }
      case Chapters.Situation: {
        return exercisesStore.situationSearch;
      }
      case Chapters.Exercise: {
        return exercisesStore.exerciseSearch;
      }
      case Chapters.Dictionary: {
        return exercisesStore.dictionarySearch;
      }
      default:
        return "";
    }
  };

  useEffect(() => {
    getTrackCategories();
    getSituationCategories();
  }, [lang]);

  return (
    <Toolbar>
      <ToggleButton
        nullable
        options={LanguageLevels}
        selectedOption={difficulty}
        setOption={exercisesStore.setDifficulty}
        variant={"round"}
      />
      {view === Chapters.Exercise && (
        <Select
          label={t("exercises.type")}
          style={{ minWidth: 220 }}
          variant={"white"}
          value={type}
          onChange={exercisesStore.setType}
          options={
            type
              ? [
                  { value: "", name: t("podcasts.all") },
                  ...exercisesTypeOptions,
                ]
              : exercisesTypeOptions
          }
        />
      )}
      {view === Chapters.Track && (
        <Select
          label={t("tags_cont.choose_category")}
          style={{ minWidth: 220 }}
          variant={"white"}
          value={exercisesStore.trackCategory}
          onChange={exercisesStore.setTrackCategory}
          options={
            exercisesStore.trackCategory
              ? [{ value: "", name: t("podcasts.all") }, ...categoryOptions]
              : categoryOptions
          }
        />
      )}
      {view === Chapters.Situation && (
        <Select
          label={t("tags_cont.choose_category")}
          style={{ minWidth: 220 }}
          variant={"white"}
          value={exercisesStore.situationCategory}
          onChange={exercisesStore.setSituaitonCategory}
          options={
            exercisesStore.situationCategory
              ? [
                  { value: "", name: t("podcasts.all") },
                  ...situationCategoryOptions,
                ]
              : situationCategoryOptions
          }
        />
      )}
      <Checkbox
        label={t("exercises.not_used_chapter")}
        value={unassigned}
        onChange={exercisesStore.setUnassigned}
      />
      <Separator />
      <Input
        search
        placeholder={t("library.search")}
        value={getSearchString()}
        onChange={exercisesStore.setSearchString}
      />

      <Separator />
      <ControlButton type="help" style={{ cursor: "not-allowed" }} />
    </Toolbar>
  );
};

export default observer(ContentSearchBar);
