import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import ExerciseService from "../../../../../../api/ExerciseService";
import { useFetching } from "../../../../../../hooks/useFetching";
import { Preview } from "..";
import {
  ExercisesPaginationCount,
  FilterModes,
  GrammarExerciseTypes,
  MediaExerciseTypes,
} from "../../../../data/constants";
import { LanguageLevels } from "../../../../../../data/common";
import {
  AddChapterModal,
  ChapterPlug,
  ChapterPreviewContainer,
  ChaptersScrollableList,
} from "../../../../components";
import { Chapters } from "../../../../../TeacherLessons/data/constants";
import cl from "./SelectExerciseModal.module.css";
import {
  Input,
  Select,
  ToggleButton,
  Toolbar,
} from "../../../../../../teacherComponents";

const SelectExerciseModal = ({
  visible,
  setVisible,
  onClick,
  withCategorySelector,
  alreadyAdded = [],
  addLoading,
}) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const lastScroll = useRef(0);

  const typeOptions = [
    ...Object.values(GrammarExerciseTypes),
    ...Object.values(MediaExerciseTypes),
  ].map((type) => ({ value: type, name: t(`exercises.${type}`) }));

  const [exercises, setExercises] = useState([]);
  const [page, setPage] = useState(0);
  const [currentExercise, setCurrentExercise] = useState({});
  const [difficulty, setDifficulty] = useState("");
  const [searchString, setSearchString] = useState("");
  const [paginationEnd, setPaginationEnd] = useState(false);
  const [type, setType] = useState("");
  const [mode, setMode] = useState(
    withCategorySelector ? FilterModes.Teacher : FilterModes.All
  );
  const [ownDisabled, setOwnDisabled] = useState(false);

  const [getExercises, isLoading] = useFetching(async () => {
    if (!visible) return;
    const { data } = await ExerciseService.getExercises({
      limit: ExercisesPaginationCount,
      offset: page * ExercisesPaginationCount,
      searchString,
      lang,
      type,
      difficulty: difficulty ? LanguageLevels.indexOf(difficulty) : null,
    });
    const { items } = data;
    if (!items.length || items.length < ExercisesPaginationCount) {
      setPaginationEnd(true);
    }
    const newExercises = (page > 0 ? [...exercises, ...items] : items).filter(
      (t, index, self) => !self.slice(0, index).some((item) => item.id === t.id)
    );
    const withoutExisting = newExercises.filter(
      (e) => !alreadyAdded.some((a) => a.id === e.id)
    );
    setExercises(withoutExisting);
    if (!currentExercise.id) setCurrentExercise(withoutExisting[0]);
  });

  const [getOwnExercises, isOwnLoading] = useFetching(async () => {
    const { data } = await ExerciseService.getSavedExercises({
      offset: 0,
      limit: 500,
      type,
      difficulty: difficulty && LanguageLevels.indexOf(difficulty),
      searchString,
      lang,
    });

    const items = data?.items ?? [];
    if (!items.length || items.length < ExercisesPaginationCount) {
      setPaginationEnd(true);
    }
    setExercises(items);
    if (!currentExercise.id) setCurrentExercise(items[0]);
  });

  const handleListScroll = (e) => {
    if (paginationEnd) return;
    const list = e.target;
    if (!isLoading && list.scrollTop > lastScroll.current) {
      if (list.scrollTop > list.scrollHeight - list.clientHeight - 100) {
        setPage(page + 1);
      }
    }
    lastScroll.current = list.scrollLeft;
  };

  const handleExerciseClick = (exerciseObj, index) => {
    if (currentExercise && currentExercise?.id === exerciseObj.id) return;
    setCurrentExercise({ ...exerciseObj, index });
  };

  const handleExerciseSelect = () => {
    onClick(currentExercise?.id);
    setVisible(false);
  };

  const resetSearch = () => {
    setPage(0);
    setPaginationEnd(false);
    setExercises([]);
    setCurrentExercise({});
  };

  useEffect(() => {
    if (page === 0 && withCategorySelector && paginationEnd) {
      setMode(FilterModes.All);
      setOwnDisabled(true);
    }
  }, [page, withCategorySelector, paginationEnd]);

  useEffect(() => {
    resetSearch();
    if (!visible) return;
    mode === FilterModes.All ? getExercises() : getOwnExercises();
  }, [searchString, lang, type, difficulty, visible, mode]);

  return (
    <AddChapterModal
      visible={visible}
      setVisible={setVisible}
      type={Chapters.Exercise}
    >
      <Toolbar>
        <ToggleButton
          nullable
          label={t("users.level")}
          options={LanguageLevels}
          selectedOption={difficulty}
          setOption={setDifficulty}
        />
        {withCategorySelector && (
          <ToggleButton
            selectedOption={mode}
            setOption={setMode}
            options={Object.values(FilterModes)}
            disabled
            availableOptions={
              ownDisabled ? FilterModes.All : Object.values(FilterModes)
            }
            withTranslations={"exercises"}
          />
        )}
        <Select
          style={{ minWidth: 212 }}
          label={t("exercises.type")}
          value={type}
          options={typeOptions}
          variant={"white"}
          onChange={setType}
        />
        <Input
          variant={"outlinedSmall"}
          placeholder={t("library.search")}
          value={searchString}
          onChange={setSearchString}
        />
      </Toolbar>
      <div className={cl.exercisesContainer}>
        <ChaptersScrollableList
          type={Chapters.Exercise}
          isLoading={isLoading || isOwnLoading}
          chapters={exercises}
          current={currentExercise}
          setCurrent={handleExerciseClick}
          onScroll={handleListScroll}
          onSelect={handleExerciseSelect}
        />
        {visible && currentExercise?.id ? (
          <ChapterPreviewContainer>
            <Preview isLoading={addLoading} exerciseId={currentExercise?.id} />
          </ChapterPreviewContainer>
        ) : (
          <ChapterPlug />
        )}
      </div>
    </AddChapterModal>
  );
};

export default observer(SelectExerciseModal);
