import { makeAutoObservable } from "mobx";
import UserService from "../api/UserService";
import player from "./player";
import { Roles } from "../data/common";

// TODO: temporary solution. remove or refactor after marketing research @saratovkin
const MarketingEmail = "marketing@thepony.app";

const userMapper = (data) => ({
  email: data.email,
  id: data.id,
  isActivated: true,
  isBlocked: data.isBanned,
  sex: data.sex,
  lang: data.lang || "en",
  languages: [data.lang || "en"],
  name: data.name,
  role: data.role || "user",
  roles: [data.role || "user"],
});

export default class Auth {
  user = {};
  role = "guest";

  constructor() {
    makeAutoObservable(this);
  }

  setRole(role) {
    this.role = role;
  }

  setUser(data) {
    const user = userMapper(data);
    // TODO: temporary solution. remove or refactor after marketing research @saratovkin
    this.setRole(user.email === MarketingEmail ? Roles.Marketing : user.role);
    player.setTargetLanguage(user.lang);
    this.user = user;
  }

  async login(email, password) {
    const res = await UserService.login(email, password);
    const { data } = res;
    if (data.role === Roles.User) {
      this.logout();
      return;
    }
    if (res.status === 204) {
      this.checkAuth();
    }
  }

  async checkAuth() {
    const res = await UserService.getMe();
    if (res.status === 200) {
      const { data } = res;
      if (data.role === Roles.User) {
        this.logout();
        return;
      }
      if (!data.id) return;
      this.setUser(data);
    }
  }

  async logout() {
    await UserService.logout();
    this.setUser({ lang: this.user?.lang, role: "guest" });
  }

  async registration(email, password) {
    // const response = await AuthService.registration(email, password);
    return null;
  }

  async resendActivationLink(email) {
    // const response = await AuthService.resendActivationLink(email);
    return null;
  }

  async genRestorePage(email) {
    // const response = await AuthService.genRestorePage(email);
    return null;
  }
}
