import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import exercisesStore from "../../../../../TeacherContent/store/exercisesStore";
import teachersStore from "../../../../../Teachers/store/teachersStore";
import {
  Select,
  Input,
  MicroButton,
} from "../../../../../../teacherComponents";
import {
  Ages,
  AgeOptions,
  LevelOptions,
} from "../../../../../TeacherContent/data/constants";
import { LanguageLevels } from "../../../../../../data/common";
import { Views } from "../../../../data/constants";
import { ReactComponent as IconCheck } from "../../../../../../assets/svg/lessons-check.svg";
import { ReactComponent as IconEdit } from "../../../../../../assets/svg/lessons-edit.svg";
import { ReactComponent as IconDelete } from "../../../../../../assets/svg/lessons-delete.svg";

import cl from "./ModalHeader.module.css";
import ModalControls from "../ModalControls/ModalControls";
import lessonsStore from "../../../../store/lessonsStore";
import StudentsSelect from "../../../../../TeacherContent/components/StudentsSelect/StudentsSelect";

const ModalHeader = ({ lesson, onUpdate, onDelete }) => {
  const { t } = useTranslation();

  const { currentStudent } = exercisesStore;
  const { currentLesson } = lessonsStore;
  const { currentTeacher } = teachersStore;
  const isTeacher = exercisesStore.view === Views.Teachers && currentTeacher;

  const [title, setTitle] = useState(lesson?.title ?? "");
  const [age, setAge] = useState(lesson?.age ?? "");
  const [difficulty, setDifficulty] = useState(lesson?.difficulty ?? "");
  const [isEdit, setIsEdit] = useState(false);

  const handleLessonUpdate = () => {
    setIsEdit(false);
    onUpdate({ title, age, difficulty });
  };

  useEffect(() => {
    setIsEdit(false);
  }, []);

  return (
    <div className={cl.lessonHeader}>
      {isEdit ? (
        <>
          <div className={cl.inputContainer}>
            <Input
              variant={"filled"}
              value={title}
              onChange={setTitle}
              autoFocus={true}
            />
          </div>

          <Select
            style={{ minWidth: 80, width: 80 }}
            variant={"lightNarrow"}
            label={t("users.age")}
            value={age}
            options={AgeOptions}
            onChange={setAge}
          />
          <Select
            style={{ minWidth: 80, width: 80 }}
            variant={"lightNarrow"}
            label={t("users.level")}
            value={difficulty}
            options={LevelOptions}
            onChange={setDifficulty}
          />
          <MicroButton
            icon={<IconCheck style={{ minWidth: 14 }} />}
            size={"regular"}
            variant={"grey"}
            isDisabled={!title}
            onClick={handleLessonUpdate}
          />
          <MicroButton
            icon={<IconDelete style={{ minWidth: 9 }} />}
            size={"regular"}
            variant={"grey"}
            onClick={() => {
              setIsEdit(false);
            }}
          />
        </>
      ) : (
        <>
          <p className={cl.title}>{title}</p>

          {typeof age === "number" && (
            <p className={cl.lessonInfoText}>{Ages[age]}</p>
          )}
          {typeof difficulty === "number" && (
            <p className={cl.lessonInfoText}>{LanguageLevels[difficulty]}</p>
          )}

          {!isTeacher && !onDelete && (
            <IconEdit
              onClick={() => setIsEdit(true)}
              style={{ cursor: "pointer" }}
            />
          )}
          {!isTeacher && currentStudent && (
            <>
              <ModalControls
                lesson={currentLesson}
                onDelete={onDelete}
                isEmpty={!currentLesson.chapters?.length}
              />
            </>
          )}

          {!isTeacher && (
            <StudentsSelect
              style={{
                marginLeft: currentStudent ? "unset" : "auto",
                marginRight: 36,
                height: 30,
                minHeight: 30,
                borderColor: "transparent",
              }}
              withReset={true}
            />
          )}
        </>
      )}
    </div>
  );
};

export default observer(ModalHeader);
